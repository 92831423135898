<template>
  <v-container
    fluid
    :style="{
      'background-color': $store.getters.getColorPalette().backgorundColorCode,
      color: $store.getters.getColorPalette().accentCode,
    }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <!-- table section -->
    <v-card :dark="$store.getters.getColorPalette().isDark">
      <template>
        <v-data-table
          :headers="headers"
          class="elevation-0"
          :items="machineParamData"
          item-key="param_id"
          :loading="loading"
          :search="search"
          loading-text="Loading...Please wait"
          :dark="$store.getters.getColorPalette().isDark"
        >
        </v-data-table>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert";
import axios from "axios";
export default {
  name: "MachineParamTable",
  props: ["machine"],
  head() {
    return {};
  },
  components: {
    InfoAlert,
  },
  data() {
    return {
      showDismissibleAlert: false,
      machineParamData: [],
      info: "",
      loading: false,
      search: "",
      expanded: [],
      op: [],
      headers: [
        {
          text: "Tag Name",
          align: "center",
          sortable: false,
          value: "tag_type",
        },
        { text: "Param Name", align: "center", sortable: true, filterable: true, value: "param_id" },
      ],
    };
  },
  mounted() {
    // console.log(this.machine,'machine')
    this.getData();
  },
  computed: {},
  methods: {
    getData() {
      if (this.machine && this.machine.machine_id) {
        // console.log("this is machine" + this.machine,"this is id"+this.machine.machine_id);
        this.loading = true;
        axios
          .post(
            this.$store.state.api + "getParamsByMachine",
            { machine_id: this.machine.machine_id },
            { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.loading = false;
              this.machineParamData = [];

              for (let i of response.data.params) {
                if (i.tag_type) {
                  let matchingParam = this.$store.state.tagTypes.find(
                    (p) => p.tag_type === i.tag_type
                  );

                  if (matchingParam) {
                    this.machineParamData.push({
                      param_id: i.name,
                      tag_type: matchingParam.name,
                    });
                  }
                }
              }
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
  },
};
</script>
