<template>
  <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <!-- <v-row > 
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Banner Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Banner Widget</span>
            </v-col>
        </v-row> -->
    <v-form
      :dark="$store.getters.getColorPalette().isDark"
      ref="widgetForm"
      v-model="widgetValid"
    >
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border:
            '1px solid ' +
            $store.getters.getColorPalette().panelBorderColorCode,
          'background-color':
            $store.getters.getColorPalette().background2ColorCode,
        }"
        flat
        outlined
        class="px-2 mb-2"
      >
        <v-card-title v-if="edit" class="sub-heading">{{
          $store.getters.getTextMap().edit_banner_widget
        }}</v-card-title>
        <v-card-title v-else class="sub-heading">{{
          $store.getters.getTextMap().add_banner_widget
        }}</v-card-title>
        <v-row>
          <v-col align-self="center">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              dense
              outlined
              v-if="!loading"
              v-model="widgetForm.label"
              :counter="30"
              :rules="[rules.required]"
              :label="$store.getters.getTextMap().widget_label"
              required
            ></v-text-field>
          </v-col>
          <v-col align-self="center">
            <v-select
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              v-if="!loading"
              v-model="widgetForm.width"
              :items="widthOptions"
              :label="$store.getters.getTextMap().widget_width"
              dense
              outlined
              item-text="label"
              item-value="value"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="widgetForm.height"
              :items="heightOptions"
              :label="$store.getters.getTextMap().widget_height"
              dense
              outlined
              item-text="label"
              item-value="value"
              :rules="[rules.required]"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="widgetForm.background_color"
              :items="$store.getters.getBackgroundColorOptions()"
              :label="$store.getters.getTextMap().background_color"
              dense
              outlined
              item-text="label"
              item-value="value"
              clearable
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
              <template v-slot:selection="{ item }">
                <div :style="{ 'background-color': item.value }">
                  {{ item.label }}
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div :style="{ 'background-color': item.value }">
                  {{ item.label }}
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!disableTimeframe" align-self="center">
            <v-select
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              v-if="!loading"
              v-model="form.time_frame"
              :items="tdOptions"
              :label="$store.getters.getTextMap().timeFrame"
              dense
              outlined
              :rules="[rules.required]"
              item-text="label"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col v-if="edit" align-self="center">
            <v-skeleton-loader
              v-if="loading"
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
            <v-text-field
              v-if="!loading"
              v-model="widgetForm.seq"
              :counter="3"
              :label="$store.getters.getTextMap().sequence"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border:
            '1px solid ' +
            $store.getters.getColorPalette().panelBorderColorCode,
          'background-color':
            $store.getters.getColorPalette().background2ColorCode,
        }"
        flat
        outlined
      >
        <!-- Single Param -->
        <!-- <v-card  :dark="$store.getters.getColorPalette().isDark"   :color="$store.getters.getColorPalette().background1ColorCode" flat outlined> -->
        <v-card-title class="sub-heading">{{
          $store.getters.getTextMap().choose_parameter
        }}</v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark" />
        <v-container fluid>
          <v-row>
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-text-field
                v-if="!loading"
                v-model="form.label"
                :counter="30"
                :rules="[rules.required]"
                :label="$store.getters.getTextMap().param_label"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader> -->
              <v-select
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                v-if="!loading"
                v-model="workspace"
                :items="workspaceOptions"
                :label="$store.getters.getTextMap().workspace"
                outlined
                dense
                item-text="label"
                item-value="value"
                required
                :rules="objectRules"
              ></v-select>
            </v-col>
            <v-col>
              <!-- <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader> -->
              <v-select
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                v-if="!loading"
                v-model="machine"
                :items="machineOptions"
                :label="$store.getters.getTextMap().machine"
                outlined
                dense
                item-text="label"
                item-value="value"
                required
                :rules="objectRules"
              ></v-select>
            </v-col>
            <v-col>
              <!-- <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader> -->
              <v-select
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                v-if="!loading"
                v-model="form.param"
                :items="paramOptions"
                :label="$store.getters.getTextMap().param"
                outlined
                dense
                item-text="label"
                item-value="value"
                required
                :rules="[rules.required, rules.nonZeroLength]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader> -->
              <v-text-field
                v-if="!loading"
                v-model="form.multiplier"
                :counter="30"
                :rules="numberRules"
                :label="$store.getters.getTextMap().multiplier"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="form.time_frame != 'rtd'">
              <v-select
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                v-if="!loading"
                v-model="form.processing"
                :items="processingOptions"
                :label="$store.getters.getTextMap().processing"
                outlined
                dense
                item-text="label"
                item-value="value"
                required
                :rules="[rules.required, rules.nonZeroLength]"
              ></v-select>
            </v-col>
            <v-col>
              <!-- <v-skeleton-loader  v-if="loading" class="mx-auto" type="text"></v-skeleton-loader> -->
              <v-text-field
                v-if="!loading"
                v-model="form.unit"
                :counter="10"
                :label="$store.getters.getTextMap().unit"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col>
              <v-checkbox
                :label="
                  showBattery ? 'Disable Battery Mode' : 'Enable Battery Mode'
                "
                v-model="showBattery"
                @change="handleCheckboxChange"
              ></v-checkbox>
            </v-col>

            <v-col v-if="edit && showBattery">
              <v-card
                :dark="$store.getters.getColorPalette().isDark"
                :style="{
                  border:
                    '1px solid ' +
                    $store.getters.getColorPalette().panelBorderColorCode,
                  'background-color':
                    $store.getters.getColorPalette().background2ColorCode,
                }"
                flat
                outlined
                class="pa-5 d-flex justify-center"
              >
              <div >
                <p>Min: {{ widget.meta.battery.minValue}}</p>
                <p>Max: {{ widget.meta.battery.maxValue }}</p>
                <v-btn  :color="$store.getters.getColorPalette().submitbtnColor" @click="showBatterDialog=true">{{ $store.getters.getTextMap().edit }}</v-btn>
              </div>
              </v-card>
            </v-col>

            <v-dialog
              v-model="showBatterDialog"
              transition="dialog-bottom-transition"
              max-width="600"
            >
              <v-card
                flat
                :color="$store.getters.getColorPalette().backgroundColorCode"
                :dark="$store.getters.getColorPalette().isDark"
                class="pa-6"
              >
                <v-card-title>Choose Battery Configuration Info :</v-card-title>
                <v-form ref="batteryForm" v-model="batteryFormValid">
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Enter Min Value"
                        outlined
                        dense
                        :rules="[...batterynumberRules, minValueRule]"
                        counter="10"
                        v-model="batteryForm.minValue"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Enter Max Value"
                        outlined
                        dense
                        :rules="[...batterynumberRules, maxValueRule]"
                        counter="10"
                        v-model="batteryForm.maxValue"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="$store.getters.getColorPalette().submitbtnColor"
                      @click="save"
                    >
                      {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                    <v-btn
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                      :dark="$store.getters.getColorPalette().isDark"
                      @click="close"
                    >
                      {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- <v-row no-gutters>
                            <v-chip class="ma-2" close color="indigo" text-color="white" v-for="param in form.params" @click:close="removeParam(param)" :key="param">
                                {{getParamName(param)}}
                            </v-chip>
                        <v-col v-if="!loading" align-self="center">
                            <v-btn :color="$store.getters.getColorPalette().submitbtnColor" small filled @click="addSingleParamToList">Add Param To List</v-btn>
                            <v-btn style="margin-left:20px;" small filled :color="$store.getters.getColorPalette().cancelbtnColor" @click="onReset">Cancel</v-btn>
                        </v-col>
                    </v-row> -->
        </v-container>
        <!-- </v-card>   -->
      </v-card>
    </v-form>
    
    <v-card 
                  :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border:
            '1px solid ' +
            $store.getters.getColorPalette().panelBorderColorCode,
          'background-color':
            $store.getters.getColorPalette().background2ColorCode,
        }"
        flat
        outlined>
        <v-card-title>Choose Color Break points</v-card-title>
                    <v-card-text>
                    <v-row v-if="!loading && form.color_meta && form.color_meta.length>0 ">
                  <v-col cols="12">
                    <v-simple-table height="150px" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().from_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().to_value}}</strong>
          </th>
          <th class="text-left">
            <strong>{{$store.getters.getTextMap().color}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :key="item.min" v-for="item in form.color_meta"
        >
          <td>{{ item.min }}</td>
          <td>{{ item.max }}</td>
          <td><span :style="'background-color:'+item.strokeStyle">{{item.strokeStyle}}</span></td>
          <td>
    <DeleteConfirmation v-on:confirm="deleteColorFromList(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this Gauge widget?">
     <v-icon small color="red">mdi-delete</v-icon>
     </DeleteConfirmation>
         </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table >
                  </v-col>
                </v-row>
                <v-form
            ref="colorForm"
            v-model="colorValid"           >
                <v-row>
                  <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="colorMin"
                    :counter="8"
                    :rules="[rules.required,rules.num]"
                    :label="$store.getters.getTextMap().from"
                    dense
                    outlined
                    required>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-text-field
                    v-if="!loading"
                    v-model="colorMax"
                    :counter="8"
                    dense
                    outlined
                    :rules="[rules.required,rules.num]"
                    :label="$store.getters.getTextMap().to"
                    required>
                    </v-text-field>
                  </v-col>
        <v-col>
            <v-row>
                <v-col v-if="showCustomColors===true">
                    <v-menu
                        v-if="!loading"
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                    <template align-self="center" v-slot:activator="{ on, attrs }">
                        <v-text-field 
                            v-model="col"
                            :label="$store.getters.getTextMap().custom_color"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="objectRules"
                        ></v-text-field>
                    </template>
                 <v-color-picker v-model="color" dot-size="25" swatches-max-height="200"></v-color-picker>
                </v-menu>
            </v-col>
                <v-col v-else>
                <v-select
              :dark="$store.getters.getColorPalette().isDark"       :color="$store.getters.getColorPalette().inputtextColor"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-if="!loading"
                    v-model="color"
                    :items="colorOptions"
                    :label="$store.getters.getTextMap().color"
                    item-text="label"
                    dense
                    outlined
                    item-value="value"
                    :rules="objectRules"
                ></v-select>
            </v-col>
            <v-col>
            <v-checkbox
         :dark="$store.getters.getColorPalette().isDark" 
                      :color="$store.getters.getColorPalette().inputtextColor"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    v-model="colorSelected"
                    :label="$store.getters.getTextMap().custom_color"
                    value="CustomColorCheck"
                    dense
                    outlined
                    ></v-checkbox>
            </v-col>
        </v-row>
    </v-col>
                  <v-col>
                    <v-btn
          v-if="!loading"
             :color="$store.getters.getColorPalette().accentCode"
            text
            small
            outlined
            @click="addColorPoint"
          >
           {{ $store.getters.getTextMap().add_color_break_point}}
          </v-btn>
                  </v-col>
                </v-row>
            </v-form>
            </v-card-text>

                  </v-card>


    <v-row no-gutters>
      <v-spacer />
      <v-col cols="auto" class="d-flex mt-2" align="right">
        <v-btn
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().submitbtnColor"
          small
          filled
          @click="onSubmit"
          >{{ $store.getters.getTextMap().submit }}</v-btn
        >
        <v-btn
          class="ml-2"
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().cancelbtnColor"
          small
          @click="onClose"
          >{{ $store.getters.getTextMap().cancel }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert";
// import CreateMultiParam from '@/components/CreateMultiParam.vue'
import axios from "axios";
import FormRules from "@/utillities/FormRules";

import DeleteConfirmation from '@/components/DeleteConfirmation'

// import DeleteConfirmation from '@/components/DeleteConfirmation'
export default {
  name: "AddBannerWidget",
  props: ["widget", "seq", "disableTimeframe"],
  components: {
    InfoAlert,
    // CreateMultiParam
    DeleteConfirmation
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      showDialog: false,
      showSingleParam: false,
      showMultiParam: false,
      showCustomColors:false,
      counter: 0,
      count: 0,
      color:null,
      colorMin:null,
      colorMax:null,
      colorValid:false,
      colorSelected:'',
      show: false,
      dialog: false,
      dialogss: false,
      loading: false,
      menu:null,
      valid: false,
      ValidMulti: false,
      widgetValid: false,
      showDismissibleAlert: false,
      info: "",
      api: "createWidgetForView",
      edit: false,
      workspace: null,
      machine: null,
      legend: false,
      textInfo: null,
      tableData: [],
      paramTemp: null,
      operatorTemp: null,
      paramArray: [],
      // paramSingleFinal:[],
      paramMultiFinal: [],
      equation: "",
      form: {
        color_meta:[]
      },
      widgetForm: {},
      machineOptions: [],
      paramOptions: [],
      processingOptions: [
        { label: "Total", value: "usage" },
        { label: "Average", value: "avg" },
        { label: "Min Value", value: "min" },
        { label: "Peak Value", value: "max" },
      ],
      widthOptions: [
        { label: "Full screen", value: 12 },
        { label: "Half screen", value: 6 },
        { label: "1/3 screen", value: 4 },
        { label: "Quarter screen", value: 3 },
        { label: "3/4 screen", value: 9 },
        { label: "Auto", value: null },
      ],
      heightOptions: [
        { label: "Fixed", value: "fixed" },
        { label: "Flex", value: "flex" },
      ],
      tdOptions: [
        { label: "Real Time", value: "rtd" },
        { label: "last 7 days", value: "7" },
        { label: "last 30 days", value: "30" },
        { label: "last 60 days", value: "60" },
        { label: "last 90 days", value: "90" },
        { label: "last 180 days", value: "180" },
        { label: "last 365 days", value: "365" },
        { label: "Yesterday", value: "1" },
        { label: "MTD", value: "month" },
        { label: "YTD", value: "year" },
      ],
      textInfoOptions: [
        { label: "Label & percentage", value: "label+percent" },
        { label: "Value Only", value: "value" },
        { label: "Value & Percentage", value: "value+percent" },
        { label: "Percentage only", value: "percent" },
        { label: "None", value: "none" },
      ],
      colorOptions:[
                {label:'red',value:'#F44336FE'},
                {label:'pink',value:'#E91E63FE'},
                {label:'purple',value:'#9C27B0FE'},
                {label:'deep-purple',value:'#673AB7FE'},
                {label:'indigo',value:'#3F51B5FE'},
                {label:'blue',value:'#2196F3FE'},
                {label:'light-blue',value:'#03A9F4FE'},
                {label:'cyan',value:'#00BCD4FE'},
                {label:'teal',value:'#009688FE'},
                {label:'green',value:'#4CAF50FE'},
                {label:'light-green',value:'#8BC34AFE'},
                {label:'lime',value:'#CDDC39FE'},
                {label:'yellow',value:'#FFEB3BFE'},
                {label:'amber',value:'#FFC107FE'},
                {label:'orange',value:'#FF9800FE'},
                {label:'deep-orange',value:'#FF5722FE'},
                {label:'blue-grey',value:'#607D8BFE'},
                {label:'grey',value:'#9E9E9EFE'},
                {label:'black',value:'#000000FE'},
                ],
      rules: FormRules.rules,
      objectRules: [(v) => !!v || "Required"],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /[0-9]+$/.test(v) || "It should be a number",
        (v) => (v && v > 0) || "It should be a valid number",
        (v) =>
          (v && v.length <= 5 && v.length > 0) ||
          "Name must be less than 5 digits",
      ],
      batterynumberRules: [
        (v) => !!v || "This field is required",
        (v) => !isNaN(v) || "Must be a number",
      ],
      countRules: [
        (v) => !!v || "Required",

        (v) => (v && v > 1) || "Number of Machine should be greater than 1",
      ],
      showBattery: false,
      showBatterDialog: false,
      batteryForm: {
        batteryModeEnabled: false,
        minValue:null,
        maxValue:null
      },
      batteryFormValid: false,
    };
  },
  computed: {
    minValueRule() {
      return (v) => {
        if (v === null || v === "") return "Min value is required";
        if (isNaN(v)) return "Must be a number";
        return true;
      };
    },
    maxValueRule() {
      return (v) => {
        if (v === null || v === "") return "Max value is required";
        if (isNaN(v)) return "Must be a number";
        if (Number(v) <= Number(this.batteryForm.minValue))
          return "Max value must be greater than Min value";
        return true;
      };
    },
    workspaceOptions() {
      let op = [];

      let g = this.$store.state.workspaces;
      for (let i of g) {
        //op.push({value:i, text: i.name })
        op.push({ value: i.workspace_id, label: i.name });
      }
      return op;
    },
  },
  methods: {
    init() {
      if (this.widget && this.widget.widget_id && this.widget.view_id) {
        console.log(this.widget, "widget");
        this.edit = true;
        this.widgetForm = Object.assign({}, this.widget);
        this.api = "updateWidget";
        if (this.widget.meta.battery) {
          this.showBattery = this.widget.meta.battery.showbattery;
          this.batteryForm.minValue = this.widget.meta.battery.minValue
          this.batteryForm.maxValue = this.widget.meta.battery.maxValue
        }

      
        this.widgetForm = this.widget;
        if (!this.widgetForm.meta) {
          this.widgetForm.meta = {};
          this.form = {};
        } else {
          this.form = this.widgetForm.meta;
          this.workspace = this.widgetForm.meta.workspace;
          this.machine = this.widgetForm.meta.machine;
          // this.legend=this.widgetForm.meta.legend
          // this.textInfo=this.widgetForm.meta.textinfo
        }
      } else {
        if (this.widget.view_id) {
          this.api = "createWidgetForView";
          this.widgetForm = Object.assign({}, this.widget);
          this.widgetForm.meta = {};
          this.widgetForm.seq = this.seq ? this.seq : 0;
          this.form = {};
        }
      }
    },
    showCustomColor(){
            this.showCustomColors = ! this.showCustomColors
        },
        addColorPoint(){
        this.$refs.colorForm.validate()
        // console.log(this.form.color_meta,'this.form.color_meta')
        // console.log(this.color,'this.color')
        if(!this.form.color_meta){
                  this.form.color_meta=[]
                }
            if(this.colorValid && this.color && this.color.hexa){
                // console.log('entered colorpoint'
                    this.form.color_meta.push({
                      strokeStyle: this.color.hexa, min: this.colorMin, max: this.colorMax
                    })
                }else{
                    this.form.color_meta.push({
                      strokeStyle: this.color, min: this.colorMin, max: this.colorMax
                    })
                }
      },
      deleteColorFromList(item){
        this.form.color_meta=this.form.color_meta.filter(x=>x.min!=item.min)
      },
    getMachineList() {
      let op = [];
      if (this.workspace) {
        let payload = {
          workspace_id: this.workspace,
        };
        axios
          .post(this.$store.state.api + "getMachinesByWorkspace", payload, {
            headers: { Authorization: "Bearer " + this.$store.state.jwt },
          })
          .then((response) => {
            if (response.data.status == "success") {
              let machines = response.data.machines;
              for (let i of machines) {
                if (i && i.name) {
                  op.push({ value: i.machine_id, label: i.name });
                } else {
                  op.push({ value: i.machine_id, label: i.machine_id });
                }
              }
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
          });
      }
      this.machineOptions = op;
    },
    getParams() {
      if (this.machine) {
        axios
          .post(
            this.$store.state.api + "getParamsByMachine",
            { machine_id: this.machine },
            { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
          )
          .then((response) => {
            if (response.data.status == "success") {
              this.loading = false;
              this.paramOptions = [];
              for (let i of response.data.params) {
                this.paramOptions.push({ label: i.name, value: i.param_id });
              }
            } else {
              this.loading = false;
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
          })
          .catch((err) => {
            this.loading = false;
            this.info = err;
            this.showDismissibleAlert = true;
          });
      }
    },
    getParamName(param) {
      let p = this.$store.getters.getParameterById(param);
      if (p) {
        return (
          this.$store.getters.getDeviceById(p["device_id"])["name"] +
          "-" +
          p["name"]
        );
      } else {
        return "Issue with getting Param Name";
      }
    },
    // addSingleParam(){
    //     this.showSingleParam=true
    // },

    // onEnter(){
    //    console.log(this.form.no_ofparams)
    //     if(this.form.no_ofparams>1){
    //       // this.loading=true
    //       this.dialogss=true
    //       this.show=true
    //     //   console.log("dialog is true")
    //     }
    //     else{
    //     //   console.log("dialog is false")
    //     }
    // },

    // addSingleParamToList(){
    //     this.$refs.usageForm.validate()
    //     if(this.valid){
    //         console.log('entered')
    //       this.loading=true
    //       if(!this.tableData){
    //         this.tableData=[]
    //       }
    //       console.log(this.tableData,'t')
    //     //   this.paramSingleFinal.push(this.form.params)
    //       this.tableData.push(
    //         {label:this.form.label, param:this.form.param}
    //       )
    //       this.form={}
    //       this.loading=false
    //     }

    // },

    onReset() {
      //evt.preventDefault()
      /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
      this.showSingleParam = false;
      this.showMultiParam = false;
      (this.show = false),
        (this.dialog = false),
        (this.dialogss = false),
        (this.counter = 0);
      this.workspace = null;
      this.machine = null;
      this.form.label = null;
      this.paramTemp = null;
      this.operatorTemp = null;
      this.equation = "";
      (this.form.no_ofparams = null),
        (this.paramNegFinal = []),
        (this.paramPosFinal = []),
        (this.paramArray = []),
        (this.paramPos = []),
        (this.paramNeg = []),
        (this.operatorArray = []),
        (this.paramMultiFinal = []),
        this.$refs.usageFormMulti.reset();
      //this.dialog=false
      this.loading = false;
      //this.$emit('close')
    },
    onSubmit() {
      console.log("called....");

      this.$refs.widgetForm.validate();
      if (this.widgetValid) {
        // this.loading = true;
        //evt.preventDefault()
        //this.form.id=(new Date).getTime().toString()
        console.log(this.batteryForm);

        this.form.workspace = this.workspace;
        this.form.machine = this.machine;
        this.widgetForm.meta = this.form;

        if (this.showBattery) {
          this.widgetForm.meta["battery"] = this.batteryForm;
        }

        // this.widgetForm.meta.legend=this.legend
        // this.widgetForm.meta.textinfo=this.textInfo
        axios
          .post(this.$store.state.api + this.api, this.widgetForm, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })

          .then((response) => {
            if (response.data.status === "success") {
              this.loading = false;
              //this.dialog=false
              this.$emit("success");
              this.$emit("close");
            } else {
              //console.log(response.data.msg)
              this.info = response.data.msg;
              //this.info = d;
              this.showDismissibleAlert = true;
              //console.log(response.data.msg)
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.info = err;
            //this.info = d;
            this.showDismissibleAlert = true;
            this.loading = false;
          });
        //this.$store.dispatch('addDevice', d)
      }
    },
    onClose() {
      (this.show = false),
        (this.dialog = false),
        (this.dialogss = false),
        (this.counter = 0);
      this.workspace = null;
      this.machine = null;
      this.form.label = null;
      this.form = {};
      this.$refs.widgetForm.reset();
      this.loading = false;
      this.$emit("close");
    },
    removeParam(param) {
      //console.log("remove param")
      //console.log(param)
      //this.stream.emit('removeParam',param)
      this.form.params = this.form.params.filter((x) => x != param);
      //this.parameter.delete(param)
    },
    deleteFromTable(item) {
      console.log(item);
      this.tableData = this.tableData.filter((x) => x.label != item.label);
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
    save() {
      this.$refs.batteryForm.validate();
      if (this.batteryFormValid) {
        this.batteryForm["showbattery"] = this.showBattery;
        this.showBatterDialog = false;
        console.log(this.batteryForm);
        console.log(this.showBatterDialog);
      } else {
        console.log("fForm validation failed");
      }
    },
    close() {
      // console.log(this.showBatterDialog);
      this.showBatterDialog = !this.showBatterDialog;
      // console.log(this.showBatterDialog);
    },
    handleCheckboxChange() {
      if (this.showBattery) {
        this.showBatterDialog = true;
      }
    },
  },
  watch: {
    workspace() {
      this.getMachineList();
    },
    machine() {
      this.getParams();
    },
    widget() {
      this.init();
    },
    color:  function (){
                if(this.color&&this.color.hexa){
                  this.col=this.color.hexa
                }else{
                  this.col=this.color
                }
        },
        pointer_color:  function (){
                if(this.pointer_color&&this.pointer_color.hexa){
                  this.form.pointer_color=this.pointer_color.hexa
                }else{
                  this.form.pointer_color=this.pointer_color
                }
        },
        colorSelected(){
            this.showCustomColor()
        }
  },
};
</script>