<template>
  <v-container
    fluid
    @contextmenu="show"
    :style="{ 'background-color': color }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      @click="dialog = true"
    >
      <v-list
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
        v-if="$store.state.settingMode && edit"
      >
        <v-list-item @click="update = true">
          <v-list-item-title
            ><strong>{{
              $store.getters.getTextMap().edit
            }}</strong></v-list-item-title
          >
          <v-list-item-icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteWidget">
          <v-list-item-title
            ><strong
              >{{ $store.getters.getTextMap().delete }}
            </strong></v-list-item-title
          >
          <v-list-item-icon>
            <v-icon color="red" small>mdi-trash-can</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="update"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().backgroundColorCode"
      >
        <v-toolbar
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
            :dark="$store.getters.getColorPalette().isDark"
            @click="update = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark"
              text
              @click="update = false"
              >{{ $store.getters.getTextMap().close }}</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <AddBannerWidget
          :widget="widget"
          :disableTimeframe="disableTimeframe"
          v-on:close="update = false"
          v-on:success="$emit('update')"
        />
      </v-card>
    </v-dialog>
    <div
      v-if="widget.meta.param"
      :style="[
        widget.height === 'fixed' ? { height: '175px'} : { height: '100%' }
        
      ]"
    >
      <v-card
        :style="[
          widget.height === 'fixed'
            ? {
                'border-radius': '5px',
                border:
                  '0px solid ' +
                  $store.getters.getColorPalette().panelBorderColorCode,
                height: '100%',
              }
            : {
                'border-radius': '5px',
                border:
                  '0px solid ' +
                  $store.getters.getColorPalette().panelBorderColorCode,
              },
        ]"
        flat
        :dark="$store.getters.getColorPalette().isDark"
        :color="$store.getters.getColorPalette().background2ColorCode"
      >
        <v-card-title
          :style="{
            background: $store.getters.getColorPalette().foregroundColorCode,
            color: $store.getters.getColorPalette().accentCode,
          }"
        >
          {{ widget.label }}
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle
          :style="{ color: $store.getters.getColorPalette().accentCode }"
        >
          {{ widget.meta.label }}
          <v-divider class="my-1"></v-divider>
          <h2>{{ val }}</h2>
          <h3
            :style="{ color: $store.getters.getColorPalette().submitbtnColor }"
          >
            {{ widget.meta.unit }}
          </h3>
        </v-card-subtitle>

        <v-card-text  class="d-flex justify-center align-center" v-if="showBattery">
          <div id="battery-container"></div>
          <div class="pl-10" v-if="showBattery&&$store.state.user && $store.state.settingMode">
              <p>Min: {{ batteryMinValue }}</p>
              <p>Max: {{ batteryMaxValue }}</p>
          </div>
        </v-card-text>

        <!-- <v-card-subtitle>
            <v-container fluid>
                <v-row dense :style="[widget.height === 'fixed' ? {'height': '100%'} : {}]">
                    <v-col cols="8" align-self="center">
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" align-item="right">
                        <div class="trend-visualization">
                            <v-progress-circular
                                :size="90"
                                :width="20"
                                :value="Math.abs((bannerData * Number(widget.meta.multiplier)).toFixed(2))"
                                :color="$store.getters.getColorPalette().inputtextColor"
                            >
                                <div class="trend-indicator" :class="{'positive': (bannerData * Number(widget.meta.multiplier)).toFixed(2) >= 0, 'negative': (bannerData * Number(widget.meta.multiplier)).toFixed(2) < 0}">
                                    <v-icon large>{{ (bannerData * Number(widget.meta.multiplier)).toFixed(2) >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                                </div>
                            </v-progress-circular>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-subtitle> -->
      </v-card>
    </div>

    <div
      v-else
      :style="[
        widget.height === 'fixed'
          ? { height: '175px', 'text-align': 'center' }
          : { 'text-align': 'center' },
      ]"
    >
      <v-icon small>mdi-hail</v-icon>
      <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
    </div>
  </v-container>
</template>
  <script>
import moment from "moment";
import * as d3 from "d3";
import InfoAlert from "@/components/InfoAlert";
// import DeleteConfirmation from '@/components/DeleteConfirmation'
import AddBannerWidget from "@/components/crud_components/widgets/AddBannerWidget";
import axios from "axios";
import { io } from "socket.io-client";
export default {
  name: "BannerWidget",
  components: {
    AddBannerWidget,
    InfoAlert,
    // DeleteConfirmation
  },
  props: ["widget", "edit", "disableTimeframe", "timeframe"],
  data() {
    return {
      showDialog: false,
      x: 0,
      y: 0,
      showMenu: false,
      loading: false,
      info: "",
      showDismissibleAlert: false,
      update: false,
      bannerData: "-",
      stream: null,
      streamData: {},
      showBattery: false,
      batteryMaxValue: null,
      batteryMinValue: null,
      
    };
  },
  created() {
    //this.socket=io('https://localhost:80');
    this.stream = io(this.$store.state.streamApi, {
      withCredentials: true,
      auth: { token: this.$store.state.jwt },
    });
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close();
  },
  mounted() {
    this.init();
    console.log(this.widget, "widget");
    if (this.widget && this.widget.meta && this.widget.meta.battery) {
      this.showBattery = this.widget.meta.battery.showbattery;
      console.log(this.showBattery);

      if (this.showBattery) {
        this.batteryMaxValue = this.widget.meta.battery.maxValue;
        this.batteryMinValue = this.widget.meta.battery.minValue;
      }
    }
  },
  computed: {
    color(){
      if(this.widget.meta && this.widget.meta.color_meta && this.widget.meta.color_meta.length>0){

        let col=this.widget.meta.color_meta.reduce((prev,curr)=>{

          if(this.val>=curr.min && this.val<=curr.max){
            return curr.strokeStyle
          }
          return prev

        },this.widget.background_color)

        if(col){
          return col
        }

      }
      return this.widget.background_color
    },
    val() {
      let dt = "-";
      if (
        this.widget.meta &&
        this.widget.meta.time_frame &&
        this.widget.meta.time_frame == "rtd"
      ) {
        const now = Date.now() / 1000;
        if (now - this.streamData["timestamp"] <= 60 * 30) {
          dt = this.streamData["data"];
        }
      } else {
        dt = this.bannerData;
      }
      return isNaN(dt)
        ? "-"
        : Math.round(dt * Number(this.widget.meta.multiplier) * 100) / 100;
    },
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      if (this.$store.state.settingMode) {
        this.$nextTick(() => {
          this.showMenu = true;
        });
      }
    },
    init() {
      this.stream.on("connect_error", (err) => {
        console.log(err.message); // prints the message associated with the error
      });
      this.stream.on("error", (data) => {
        console.log(data);
      });
      this.stream.on("ack", (data) => {
        console.log(data);
      });
      this.stream.on("param", (data) => {
        if (data.constructor == String) {
          data = JSON.parse(data);
        }
        //   this.val=data.data
        // console.log(data)
        this.streamData = data;
      });
      if (this.widget && this.widget.meta && this.widget.meta) {
        if (
          this.widget.meta.time_frame &&
          this.widget.meta.time_frame == "rtd"
        ) {
          this.stream.emit("getParam", this.widget.meta.param);
        } else {
          this.getData();
        }
      }
    },
    getData() {
      // if(this.widget.height==='fixed'){
      // }
      // console.log(this.widget)
      this.bannerData = "-";
      let processing = "usage";
      let from_date;
      if (this.timeframe && this.disableTimeframe) {
        switch (this.timeframe) {
          case "7":
            from_date = moment().subtract(7, "days").format("YYYYMMDD");
            break;

          case "30":
            from_date = moment().subtract(30, "days").format("YYYYMMDD");
            break;

          case "60":
            from_date = moment().subtract(60, "days").format("YYYYMMDD");
            break;

          case "90":
            from_date = moment().subtract(90, "days").format("YYYYMMDD");
            break;

          case "180":
            from_date = moment().subtract(180, "days").format("YYYYMMDD");
            break;

          case "365":
            from_date = moment().subtract(365, "days").format("YYYYMMDD");
            break;

          case "yesterday":
            from_date = moment().subtract(1, "days").format("YYYYMMDD");
            break;
          case "ytd":
            from_date = moment().startOf("year").format("YYYYMMDD");
            break;

          default:
            from_date = moment()
              .subtract(1, "days")
              .startOf("month")
              .format("YYYYMMDD");
            break;
        }
      } else {
        if (
          this.widget.meta &&
          this.widget.meta.time_frame &&
          this.widget.meta.time_frame != "rtd"
        ) {
          if (
            this.widget.meta.time_frame === "month" ||
            this.widget.meta.time_frame === "month"
          ) {
            from_date = moment()
              .startOf(this.widget.meta.time_frame)
              .format("YYYYMMDD");
          } else {
            from_date = moment()
              .subtract(parseInt(this.widget.meta.time_frame), "days")
              .format("YYYYMMDD");
          }
        } else {
          from_date = moment().subtract(30, "days").format("YYYYMMDD");
        }
      }
      let to_date = moment().format("YYYYMMDD");
      if (this.widget && this.widget.meta) {
        // let param=this.$store.getters.getParamById(this.widget.meta.param)
        // console.log(param)
        if (this.widget && this.widget.meta && this.widget.meta.processing) {
          switch (this.widget.meta.processing) {
            case "usage":
              processing = "usage";
              break;
            case "avg":
              processing = "avg";
              break;
            case "min":
              processing = "min";
              break;
            case "max":
              processing = "max";
              break;
            default:
              processing = "usage";
          }
        }
        let payload = {
          parameters: [this.widget.meta.param],
          from_date: from_date,
          to_date: to_date,
        };
        axios
          .post(this.$store.state.api + "getTotalParamUsage", payload, {
            headers: { Authorization: "Bearer " + this.$store.state.jwt },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.bannerData = Number(response.data.data[processing]).toFixed(
                2
              );
            } else {
              this.info = response.data.msg;
              this.showDismissibleAlert = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
          });
      }
    },
    deleteWidget() {
      this.loading = true;
      axios
        .post(this.$store.state.api + "deleteWidget", this.widget, {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.dialog = false;
            this.loading = false;
            this.$emit("update");
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    cancel() {
      this.showDialog = false;
    },
    confirm() {
      this.showDialog = false;
    },
    drawBattery() {
      let min = this.batteryMinValue;
      let max = this.batteryMaxValue;
      let actual = this.val;

      console.log(min, max, actual);

      const width = 200;
      const height = 80;
      const batteryLevel = Math.max(
        0,
        Math.min(1, (actual - min) / (max - min))
      );
      const fillWidth = batteryLevel * (width - 10);
      const percentage = Math.round(batteryLevel * 100) + "%";

      d3.select("#battery-container").selectAll("*").remove();
      const svg = d3
        .select("#battery-container")
        .append("svg")
        .attr("width", width + 10)
        .attr("height", height);

      // Outer Battery
      svg
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", height)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr("stroke", "#333")
        .attr("stroke-width", 5)
        .attr("fill", "none");

      // Battery Cap
      svg
        .append("rect")
        .attr("x", width)
        .attr("y", height / 4)
        .attr("width", 10)
        .attr("height", height / 2)
        .attr("fill", "#333")
        .attr("rx", 2)
        .attr("ry", 2);

      svg
        .append("rect")
        .attr("x", 5)
        .attr("y", 5)
        .attr("width", 0)
        .attr("height", height - 10)
        .attr("rx", 7)
        .attr("ry", 7)
        .attr("fill", batteryLevel > 0.2 ? "#4CAF50" : "red")
        .transition()
        .duration(500)
        .attr("width", fillWidth);

      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height / 2 + 5)
        .attr("text-anchor", "middle")
        .attr("fill", "#000")
        .attr("font-size", "18px")
        .attr("font-weight", "bold")
        .text(percentage);


        
    }
    
  },
  watch: {
    widget: {
      handler() {
        this.init();
      },
      deep: true,
    },
    timeframe: {
      handler() {
        this.init();
      },
      deep: true,
    },
    val() {
      this.drawBattery();
    },
    //  graphType: function (){
    //       //this.init()
    //       this.createChart()
    //   }
  },
};
</script>
  <style scoped>
</style>