import Vue from "vue";
import VueRouter from "vue-router";
//import Home from '../views/Home.vue'
import LoginScreen from "@/views/LoginScreen";
import Dashboard from "@/views/Dashboard";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "LoginScreen",
    component: LoginScreen,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/accessdenied",
    name: "AccessDenied",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/AccessDeniedPage.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
  },
  {
    path: "/user",
    name: "User Access",
    component: () => import("@/views/User.vue"),
  },
  {
    path: "/data",
    name: "Data",
    component: () => import("@/views/Data.vue"),
  },
  {
    path: "/monitor",
    name: "Monitor",
    component: () => import("@/views/Monitor.vue"),
  },
  {
    path: "/applications",
    name: "Applications",
    component: () => import("@/views/Applications.vue"),
  },
  // {
  //   path: '/sldShapes',
  //   name: 'SLD Shapes',
  //   component: () => import( '@/views/SLDShapes.vue')
  // },
  {
    path: "/views",
    name: "Views",
    component: () => import("@/views/EntureViews.vue"),
  },
  {
    path: "/fullview/:id",
    name: "FullView",
    component: () => import("@/views/FullScreenView.vue"),
    props: true,
  },
  {
    path: "/datalogdetailtable/:id",
    name: "Data Log Details Table",
    component: () => import("@/components/tables/DataLogDetailsTable.vue"),
    props: true,
  },
  {
    path: "/modules",
    name: "Modules",
    component: () => import("@/views/EntureModules.vue"),
    children: [
      {
        path: "busbar_temp/:id",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/modules/BusbarModulePage.vue"
          ),
        props: true,
      },
      {
        path: "pq/:id",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/modules/PQModulePage.vue"
          ),
        props: true,
      },
      {
        path: "esg/:id",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/modules/ESGModulePage.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/views/Reports.vue"),
    children: [
      {
        path: "generatedReports",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/GenerateReportPage"),
      },
      {
        path: "customReports",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/CustomReportsPage"),
      },
      {
        path: "compareReports",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/CompareParamsPage"),
      },
    ],
  },
  {
    path: "/engineeringPanel",
    name: "Engineering Panel",
    component: () => import("@/views/EngineeringPanel.vue"),
    children: [
      {
        path: "machineParams",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/tables/MachineParamViewTable.vue"
          ),
      },
      {
        path: "modbusParams",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/tables/ModbusParamViewTable.vue"
          ),
      },
      {
        path: "paramCounts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/tables/ParamCountTable.vue"
          ),
      },
      {
        path: "APIDataCounts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/tables/APIDataCountTableView.vue"
          ),
      },
      {
        path: "worspaceMachineParams",
        component: () =>
          import("@/components/tables/WorkspaceMachineViewTable.vue"),
      },
    ],
  },
  {
    path: "/machines",
    name: "Machines",
    component: () => import("@/views/Machines.vue"),
    children: [
      {
        path: "workspace/:id",
        component: () => import("@/pages/MachinePage.vue"),
        props: true,
        // props:route=>({
        //   workspace_id:route.query.workspace_id,
        // })
      },
      // {
      //   path:'machineList',
      //   name:'machines/machineList',
      //   component: () => import( '@/pages/MachinePage.vue'),
      //   props:route=>({
      //     workspace_id:route.query.workspace_id,
      //   })
      // }
    ],
  },
  {
    path: "/processes",
    name: "Processes",
    component: () => import("@/views/Processes.vue"),
    children: [
      {
        path: ":id",
        component: () => import("@/pages/MachinePage.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/oee",
    name: "OEE",
    component: () => import("@/views/OEE.vue"),
  },
  {
    path: "/machine_production_plan",
    name: "MachineProductionPlan",
    component: () => import("@/views/MachineProductionPlan.vue"),
  },
  {
    path: "/sso/iapps/login",
    name: "SSOLogin",
    component: () => import("@/views/SSOLogin.vue"),
  },
  {
    path: "/sso/iapps/keycloak/login",
    query: {},
    name: "SSOKeyCloakLogin",
    component: () => import("@/views/SSOKeyCloakLogin.vue"),
  },
  {
    path: "/sso/iapps/keycloak/login:state", // Use a route parameter for 'state'
    redirect: (to) => {
      return {
        path: "/sso/iapps/keycloak/login",
        query: { state: to.params.state },
      }; // Redirect to the base path with the 'state' query parameter
    },
  },
  {
    path: "/controlpanel",
    name: "ControlPanel",
    component: () => import("@/views/ControlPanel.vue"),
  },
  {
    path: "/switchorganisation",
    name: "SwitchOrganisationView",
    component: () => import("@/views/SwitchOrganisationView.vue"),
  },
  {
    path: "/changeorganisation",
    name: "ChangeOrganisationView",
    component: () => import("@/views/ChangeOrganisationView.vue"),
  },
  {
    path: "/createorganisation",
    name: "CreateOrganisationView",
    component: () => import("@/views/CreateOrganisationView.vue"),
  },
  {
    path: "/addoemlogo",
    name: "AddOEMLogoView",
    component: () => import("@/views/AddOEMLogoView.vue"),
  },
  {
    path: "/organisationdetails",
    name: "OrganisationDetails",
    component: () => import("@/views/OrganisationDetails.vue"),
  },
  {
    path: "/devices",
    name: "Devices",
    component: () => import("@/views/Devices.vue"),
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("@/views/Groups.vue"),
  },
  {
    path: "/alarms",
    name: "Alarms",
    component: () => import("@/views/Alarms.vue"),
  },
  {
    path: "/roles",
    name: "Roles",
    component: () => import("@/views/Roles.vue"),
  },
  {
    path: "/derived",
    name: "Derived",
    component: () => import("@/views/Derived.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "shifts",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ShiftsPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "lists",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ListsPage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "dependent_lists",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/DependentListPage.vue"
          ),
      },
      {
        path: "sku",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/derived/SKUPage.vue"),
      },
      {
        path: "itemCodes",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ItemCodePage.vue"
          ),
      },
      {
        path: "products",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ProductsPage.vue"
          ),
      },
      {
        path: "location",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/LocationPage.vue"
          ),
      },
      {
        path: "locationEventType",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/LocationEventTypePage.vue"
          ),
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "equations",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ParamEquationsPage.vue"
          ),
      },
      {
        path: "comboParams",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ComboParamPage.vue"
          ),
      },
      {
        path: "calculatedParams",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/CalculatedParamPage.vue"
          ),
      },
      {
        path: "sheets",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/SheetsPage.vue"
          ),
      },
      {
        path: "ESGScopeConversion",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ESGScopeConversionPage.vue"
          ),
      },
      {
        path: "ghgEmissions",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/ESGScopeConversionPageTemp.vue"
          ),
      },
      {
        path: "targets",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/TargetsPage.vue"
          ),
      },
      {
        path: "tariff",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/derived/TariffsPage.vue"
          ),
      },
      {
        path: "words",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/WordPage.vue"),
      },
      {
        path: "OEMDevices",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/pages/OEMDevicesPage.vue"),
      },
    ],
  },
  {
    path: "/manufacturing",
    name: "Manufacturing",
    component: () => import("@/views/Manufacturing.vue"),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: "batch",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/manufacturing/Batches.vue"
          ),
        children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "overview",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/ManufacturingOverview.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "trackBatch",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/BatchTrackingPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "productionPlanning",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/ProductionPlanningPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "batches",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/BatchesPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "batchMovement",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/BatchMovementPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "rawMaterials",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/RawMaterialsPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "SafetyIncident",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/SafetyIncidentPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "SafetyInspection",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/batch/SafetyInspectionPage.vue"
              ),
          },
        ],
      },
      {
        path: "store",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/manufacturing/Stores.vue"
          ),
        children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "vouchers",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/stores/VoucherPage.vue"
              ),
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: "requests",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/stores/VoucherRequestPage.vue"
              ),
          },
        ],
      },
      {
        path: "checklist",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/manufacturing/CheckLists.vue"
          ),
        children: [
          {
            path: "home",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/checklist/ChecklistDataLists.vue"
              ),
          },
          {
            path: "configuration",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/checklist/ChecklistConfigurations.vue"
              ),
          },
          {
            path: "checklists",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/checklist/ChecklistHistoricalDataList.vue"
              ),
          },
        ],
      },
      {
        path: "logbooks",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/manufacturing/ManufacturingLogbook.vue"
          ),
        children: [
          {
            path: "home",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/logbook/ManufacturingLogbookPage.vue"
              ),
          },
          {
            path: "configuration",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/logbook/ManufacturingLogbookConfigurationsPage.vue"
              ),
          },
          {
            path: "logbooks",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/pages/manufacturing/logbook/ManufacturingLogbookHistoricalPage.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/logbook",
    name: "Log Book",
    component: () => import("@/views/LogBook.vue"),
  },
  {
    path: "/profile",
    name: "User Profile",
    component: () => import("@/views/UserProfile.vue"),
  },
  {
    path: "/sustainability",
    name: "Sustainability",
    component: () => import("@/views/Sustainability.vue"),
  },
  {
    path: "/esg",
    name: "ESG",
    component: () => import("@/views/ESG.vue"),
  },
  {
    path: "/ghg",
    name: "GHG Emissions",
    component: () => import("@/views/ESGTemp.vue"),
  },
  {
    path: "/airquality",
    name: "Air Quality",
    component: () => import("@/views/AirQuality.vue"),
    children: [
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/application/AirQualityPage.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/taskmanagment",
    name: "Task Managment",
    component: () => import("@/views/TaskManagment.vue"),
    children: [
      {
        path: "activity",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/pages/application/ActivityPage.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/machines/:workspaceId",
    name: "Workspace",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/MachinePage.vue"),
    props: true,
  },
  {
    path: "/entureAdmin",
    name: "Enture Admin",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/EntureAdmin.vue"),
    // props: true
  },

  /*{
      path: '/help',
      name: 'Help',
      component: () => import('@/views/Help.vue'),
      children:[
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
          path: '/',
        
        component: () => import( '@/pages/help/WelcomePage.vue')
        },
        {
          path: 'aboutLogin',
          component: () => import( '@/pages/help/AboutLogin.vue')
          
        },
        
        {
      path: 'aboutDashBoard',
      component: () => import( '@/pages/help/AboutDashBoard.vue')
      
    },
    {
      path: 'aboutMonitor',
      component: () => import( '@/pages/help/AboutMonitor.vue')
      
    },
    {
      path: 'aboutViews',
      component: () => import( '@/pages/help/AboutViews.vue')
      
    },
    {
      path: 'aboutAction&Trigger',
      component: () => import( '@/pages/help/AboutActionTrigger.vue')
      
    }, 
    {
      path: 'aboutSettings',
      name: "About Settings",
      component: () => import( '@/pages/help/AboutSettings.vue')
    },
    {
      path: 'aboutControlPanel',
      component: () => import( '@/pages/help/AboutControlPanel.vue')
    }, 
    {
      path: 'AboutUAM',
      name: "About UAM",
      component: () => import( '@/pages/help/AboutUAM.vue')
    },
    {
      path: 'AboutWorkspace',
      name: "About Workspace",
      component: () => import( '@/pages/help/AboutWorkspace.vue')
    },
    {
      path: 'AboutModbusMqttdevice',
      name: "About ModbusMqttdevice",
      component: () => import( '@/pages/help/AboutModbusMqttdevice.vue')
    },
    {
      path: 'AboutModbusMqttparameter',
      name: "About ModbusMqttparameter",
      component: () => import( '@/pages/help/AboutModbusMqttparameter.vue')
    },
    {
      path: 'AboutUsagereport',
      name: "About Usagereport",
      component: () => import( '@/pages/help/AboutUsagereport.vue')
    },
      ]
    },*/
  // {
  //   path: '/HelpMenu',
  //   component: () => import('@/components/HelpMenu.vue'),
  //   children: [
  //     {
  //       path: '/AboutDashBoard',
  //       components:() => import('@/pages/AboutDashBoard')
  //     }
  //   ]
  // },
  // {
  //   path: '/AboutDashBoard',
  //   name: 'DashBoard',
  //   component: () => import( '@/pages/AboutDashBoard.vue')

  // },
  // {
  //   path: '/AboutReport',
  //   name: 'About Reports',
  //   component: () => import('@/pages/AboutReport.vue')
  // },
  // {
  //   path: '/AboutGeneratedReport',
  //   name: 'Generated Reports',
  //   component: () => import( '@/pages/AboutGeneratedReport.vue')
  // },
  // {
  //   path: '/AboutCustomReport',
  //   name: 'Custom Reports',
  //   component: () => import( '@/pages/AboutCustomReport.vue')
  // },
  // {
  //   path: '/AboutCompareReport',
  //   name: 'Compare Reports',
  //   component: () => import( '@/pages/AboutCompareReport.vue')
  // },
  // {
  //   path: '/CustomParameterReport',
  //   name: 'Parameter Reports',
  //   component: () => import( '@/pages/CustomParameterReport.vue')
  // },
  // {
  //   path: '/CustomTimeOfDayReport',
  //   name: 'Time Of Day Reports',
  //   component: () => import( '@/pages/CustomTimeOfDayReport.vue')
  // },
  // {
  //   path: '/CustomTotalUsageReport',
  //   name: 'Total Consumed Reports',
  //   component: () => import( '@/pages/CustomTotalUsageReport.vue')
  // },
  // {
  //   path: '/CompareParameterReport',
  //   name: 'Parameter Comparison',
  //   component: () => import( '@/pages/CompareParameterReport.vue')
  // },
  // {
  //   path: '/AboutDevices',
  //   name: "About Devices",
  //   component: () => import( '@/pages/AboutDevices.vue')
  // },
  // {
  //   path: '/AboutSettings',
  //   name: "Settings",
  //   component: () => import( '@/pages/AboutSettings.vue')
  // },
  // {
  //   path: '/AboutFeatures',
  //   name: "Features",
  //   component: () => import( '@/pages/AboutFeatures.vue')
  // },
  // {
  //   path: '/AboutFAQ',
  //   name: "FAQ",
  //   component: () => import( '@/pages/AboutFAQ.vue')
  // }
];

const router = new VueRouter({
  // mode: 'history',
  routes,
});

export default router;
