<template>
    <v-container fluid @contextmenu="show" :dark="$store.getters.getColorPalette().isDark"
        :style="{ 'background-color': widget.background_color }">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert = false"
            :info="info" />
        <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y @click="dialog = true"
            v-if="$store.state.settingMode && edit">
            <v-list :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-list-item @click="update = true">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().edit }}</strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item @click="deleteWidget">
                    <v-list-item-title><strong>{{ $store.getters.getTextMap().delete }}
                        </strong></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon color="red" small>mdi-trash-can</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-dialog v-model="update" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="update = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ widget.label }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="update = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <AddDaywiseMachineStatusTableWidget :widget="widget" v-on:close="update = false"
                    v-on:success="$emit('update')" />
            </v-card>
        </v-dialog>
        <v-dialog v-model="showFilterDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showFilterDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Filter</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="showFilterDialog = false">{{
                            $store.getters.getTextMap().close }}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-row>
                    <v-col cols="12">
                        <DateRangeForm v-on:close="showFilterDialog = false" v-on:value="updateFilter" />
                    </v-col>
                    </v-row>

        </v-card>

        </v-dialog>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <div v-if="widget.meta && widget.meta.data" ref="vis"
            :style="[widget.height === 'fixed' ? { 'background-color': widget.background_color ? widget.background_color : $store.getters.getColorPalette().background2ColorCode, 'height': '150px', } : {}]">
            
            <span class="text-center text-h6" :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }" >
                {{ widget.label }}
                <!-- <h3 class="text-center my-3">{{ widget.label }}</h3>  -->
            </span>
            <span v-if="
        $store.state.user" >
                <v-icon v-if="filterActive" @click="deactivateFilter" small>mdi-filter-outline</v-icon>
                <v-icon v-else small @click="activateFilter">mdi-filter-off-outline</v-icon>
            </span>
            <v-simple-table :color="$store.getters.getColorPalette().backgroundColorName" fixed-header dense
            height="100%"
                :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                <template v-slot:default>
                    <thead
                        :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                        <tr>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                Machine Name</th>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '"
                              v-for="tag in tags" :key="tag"
                                :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                {{tagTypeName[tag]?tagTypeName[tag]:tag }}</th>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                Running</th>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                Idling</th>
                            <th :class="' text-center  text-subtitle-1 text-capitalize '"
                                :style="{ 'background-color': $store.getters.getColorPalette().foregroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                                Offline</th>
                        </tr>
                    </thead>
                    <tbody :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode }"
                        v-if="tempDataFinale.length > 0">
                        <tr v-for="(machine, ind) in tableData" :key="ind"
                            :style="{ 'background-color': $store.getters.getColorPalette().backgroundColorCode, 'color': $store.getters.getColorPalette().accentCode }">
                            <td class="text-center">{{ $store.getters.getMachineById(machine.machine_id).name }}</td>
                            <!-- <td class="text-center">{{ new Date(machine.date).toISOString().split('T')[0] }}</td> -->
                            <td class="text-center" v-for="tag in tags" :key="tag" >{{ machine[tag] }}</td>
                            <td class="text-center">{{ formatDuration(machine.running) }}</td>
                            <td class="text-center">{{ formatDuration(machine.idling) }}</td>
                            <td class="text-center">{{ formatDuration(machine.offline) }}</td>
                            <!-- <td class="text-center">
                                {{
                                    Math.floor(machine.total_duration / 3600) }} hrs,
                                {{
                                    Math.floor((machine.total_duration % 3600) / 60) }} mins
                            </td> -->
                        </tr>
                    </tbody>
                    <div v-else class="text-center">
                        <div class="text-center">
                            <v-icon class="text-center"
                                :style="{ color: $store.getters.getColorPalette().navigation_icon }">mdi-database</v-icon>
                            No data
                        </div>
                    </div>
                </template>
            </v-simple-table>

        </div>
        <div v-else
            :style="[widget.height === 'fixed' ? { 'height': '175px', 'text-align': 'center' } : { 'text-align': 'center' }]">
            <v-icon small>mdi-hail</v-icon>
            <h5>{{ $store.getters.getTextMap().not_configured }}</h5>
        </div>
    </v-container>
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios';
import AddDaywiseMachineStatusTableWidget from '@/components/crud_components/widgets/AddDaywiseMachineStatusTableWidget'
import DateRangeForm from '@/components/forms/DateRangeForm'
import moment from 'moment';


export default {
    name: "DaywiseMachineStatusTableWidget",
    components: {
        InfoAlert,
        AddDaywiseMachineStatusTableWidget,
        DateRangeForm
    },
    props: {
        widget: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },

    },
    mounted() {
        // console.log(this.widget);
        if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.data.length > 0) {
            this.createInputData()
        }

    },
    data() {
        return {
            showMenu: false,
            showDismissibleAlert: false,
            info: false,
            update: false,
            dialog: false,
            x: 0,
            y: 0,
            loading: false,
            payload: null,
            inputData: [],
            graphData: {},
            multiParamGraphData: {},
            tempDataFinale: [],
            tagData:[],
            showFilterDialog:false,
            filterActive:false,
            filterForm:{},
            tagTypeName:{
                'active_energy':"Energy Units Consumed",
                'flow_totalizer':"Flow Total"
            },
            tagTypeMap:{
                'pf':'mean',
                'active_energy':'usage',
                'flow_totalizer':'usage',
                'active_power':'mean'
            },
        }
    },
    computed:{
        tags(){
            let tags=[]

            if(this.widget && this.widget.meta && this.widget.meta.tags){
                tags=this.widget.meta.tags
            }


            return tags

        },
        showFilterWindow(){
            if(this.showFilterDialog){
                return true
            }
            return false

        },
        tableData(){
            let tableData={}
            if(this.tempDataFinale){

                tableData=this.tempDataFinale.reduce((agg,curr)=>{
                    let key=curr['machine_id']
                    if(!(key in agg)){
                        agg[key]={
                            machine_id:curr['machine_id'],
                            // name:curr['machine_name'],
                            running:0,
                            offline:0                            
                        }
                    }
                    if(!(curr['status'] in agg[key])){
                        agg[key][curr['status']]= Number(curr['total_duration'])
                    }else{
                        agg[key][curr['status']]+= Number(curr['total_duration'])
                    }
                    return agg
                },{})
            }
            if(this.tagData){
                tableData=this.tagData.reduce((agg,curr)=>{
                    let key=curr['machine_id']
                    if(!(key in agg)){
                        agg[key]={
                            machine_id:curr['machine_id'],
                            name:curr['machine_name'],
                            running:0,
                            offline:0                            
                        }
                    }
                    if(!(curr['tag_type'] in agg[key])){
                        agg[key][curr['tag_type']]= Number(curr['usage'])
                    }
                    return agg

                },tableData)
            }

            return Object.values(tableData)
        }

    },
    methods: {
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            if (this.$store.state.settingMode) {
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }
        },
        activateFilter(){

                this.showFilterDialog=true
                this.filterActive=true
                this.$nextTick(() => {
                    this.showFilterDialog=true
                    this.filterActive=true
                })
                // console.log(this.showFilterDialog)
        },
        deactivateFilter(){

                this.showFilterDialog=false
                this.filterActive=false
                this.getData()
        },
        formatDuration(total_duration){
            let st='-'
            if(total_duration){
                st=Math.floor(total_duration / 3600).toString()+' hrs, '+     Math.floor((total_duration % 3600) / 60).toString()+' mins'

            }
            return st;
                                 
        },
        updateFilter(filterData){
            this.filterForm=filterData
            // console.log(filterData)
            this.showFilterDialog=false
            this.getData()
        },
        createInputData() {
            let temp = []
            for (let i of this.widget.meta.data) {
                temp.push({
                    machine_id: i.machine_id
                })
            }
            this.inputData = temp
        },
        init() {
            this.getData()

        },

        deleteWidget() {
            this.loading = true
            axios.post(this.$store.state.api + 'deleteWidget', this.widget, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.dialog = false
                        this.loading = false
                        this.$emit('update')
                    }
                }).catch(err => {
                    console.error(err)
                    this.loading = false
                });
        },

        getFromDate(date_format) {
            let from_date = moment();

            // console.log(this.widget, "timeframe");


            if (this.widget && this.widget.widget_id && this.widget.meta && this.widget.meta.time_frame) {

                switch (this.widget.meta.time_frame) {
                    case '7':
                        from_date = date_format ? moment().subtract(7, 'days').format(date_format) : moment().subtract(7, 'days');
                        break;
                    case '30':
                        from_date = date_format ? moment().subtract(30, 'days').format(date_format) : moment().subtract(30, 'days');
                        break;
                    case '60':
                        from_date = date_format ? moment().subtract(60, 'days').format(date_format) : moment().subtract(60, 'days');
                        break;
                    case '90':
                        from_date = date_format ? moment().subtract(90, 'days').format(date_format) : moment().subtract(90, 'days');
                        break;
                    case '180':
                        from_date = date_format ? moment().subtract(180, 'days').format(date_format) : moment().subtract(180, 'days');
                        break;
                    case '365':
                        from_date = date_format ? moment().subtract(365, 'days').format(date_format) : moment().subtract(365, 'days');
                        break;
                    case '1':
                        from_date = date_format ? moment().subtract(1, 'days').format(date_format) : moment().subtract(1, 'days');
                        break;
                    case 'ytd':
                        from_date = date_format ? moment().startOf('year').format(date_format) : moment().startOf('year');
                        break;
                    default:
                        from_date = date_format ? moment().subtract(30, 'days').startOf('month').format(date_format) : moment().startOf('month');
                        break;
                }

                // console.log(this.timframe);

                // console.log(from_date);


            }

            return from_date
        },


        async getData() {
            try {
                // if (this.widget.height === 'fixed') {
                //     this.layout.height = 160;
                //     this.layout.font = { size: 8, color: this.$store.getters.getColorPalette().accentCode };
                //     this.layout.margin = { t: 40, b: 40, l: 40, r: 40 };
                // }
                // this.layout.colorway = this.$store.getters.getColorPalette().colorTypesCodeArray;
                this.graphData = {};
                this.multiParamGraphData = {};

                let from_date = moment().subtract(1, 'days');
                from_date = this.getFromDate('YYYYMMDD');
                let to_date = moment().format('YYYYMMDD');
                if(this.filterActive){
                    if(this.filterForm && this.filterForm.from_date && this.filterForm.to_date){
                        from_date=moment(this.filterForm.from_date, 'YYYY-MM-DD').format('YYYYMMDD')
                        to_date=moment(this.filterForm.to_date, 'YYYY-MM-DD').format('YYYYMMDD')
                    }

                }
                // console.log(from_date);
                // console.log(to_date);

                if (this.widget && this.widget.meta && this.widget.meta.data && this.inputData) {
                    this.tempDataFinale = [];

                    // console.log(this.inputData, "inputdata");
                    let payload = {
                        from_date: from_date,
                        to_date: to_date,
                        // total: true,
                        machines: []
                    }

                    // console.log(payload);

                    for (let i of this.inputData) {
                        payload['machines'].push(i.machine_id)
                    }

                    // console.log(payload,"payload");
                    this.getTagData(from_date,to_date,payload['machines'])

                    if (payload) {
                        this.loading=true
                        let response = await axios.post(this.$store.state.api + 'getDayWiseMachineStatusData', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } });
                        // console.log(response, "response");
                        this.payload = payload
                        if (response.data.status == 'success') {
                            this.loading = false;
                            let data = response.data.data
                            // console.log(data);
                            // responseData.push(data)


                            this.tempDataFinale = [...data]

                        } else {
                            this.loading = false;
                            this.info = response.data.msg;
                            this.showDismissibleAlert = true;
                        }
                    }


                    // console.log(responseData);

                    // this.createMultipleParamChart();
                    this.loading = false;
                }

            } catch (err) {
                this.loading = false;
                this.info = err;
                this.showDismissibleAlert = true;
            }
        },
        getTagData(from_date, to_date,machines){
            if(this.widget.meta.tags && this.widget.meta.tags.length>0){

                let d={
                    from_date,
                to_date,
                tag_types:this.widget.meta.tags,
                machines:machines
            }
            
            axios.post(this.$store.state.api + "getUsageByMachines",d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                // console.log(response);
                if (response.data.status == "success") {
                    // console.log(response.data.data,"Response");
                    this.tagData=response.data.data  
                    // this.getMachineTableData()
                }
                else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
            })
            .catch(error=>{
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            })
        }
        },
    },
    watch: {
        widget() {
            this.createInputData()
        },

        inputData() {
            this.init()
        },
        // filterActive(filterVal){
        //     // console.log(filterVal)
        //     if(filterVal){
        //         console.log('filter active')

        //         this.showFilterDialog=true
        //         console.log(this.showFilterDialog)
        //     }else{{
        //         this.showFilterDialog=false
        //     }}
        // }

    }
}
</script>

<style></style>