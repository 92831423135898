import English from "@/utillities/languages/English";

export default {
  ...English,
  dependent: "आधारित",
  key: "की",
  column: "स्तंभ",

  new: "नवीन",

  use: "वापरा",
  dynamic_excel_sheet: "डायनॅमिक एक्सेल शीट",
  sheet_type: "शीट प्रकार",

  issue_no: "अंक क्रमांक",
  issue_date: "अंक दिनांक",

  checklist_status_sheet: "तपासणी सूची स्थिती पत्रक",
  all: "सर्व",

  start: "सुरु करा",
  filling: "भरणे",

  accept: "स्वीकारा",
  reject: "नाकार",
  move_to_next_stage: "पुढील टप्प्यावर जा",
  save_as_draft: "मसुदा म्हणून जतन करा",
  save_and_submit: "जतन करा आणि सबमिट करा",
  closure: "समाप्ती",
  duration: "कालावधी",
  refill_request: "पुनर्भरणाची विनंती",
  is_be_cancelled: "रद्द होईल",
  delete_this: "हे हटवा",
  event: "कार्यक्रम",

  for: "साठी",
  of: "चे",
  downtime: "डाऊनटाइम",

  metrics: "मेट्रिक्स",
  oee_by_day: "दिवसाच्या अनुसार OEE",

  ideal_cycle_time: "आदर्श चक्र वेळ",

  minutes: "मिनिटे",
  production_time: "उत्पादन वेळ",
  back: "मागे",
  down_times: "डाउन टाइम्स",

  top_5_reasons: "टॉप 5 कारणे",
  top_5_categories: "टॉप 5 श्रेणी",
  enter_apparent_energy_manually: "स्पष्ट ऊर्जा मॅन्युअली प्रविष्ट करा",

  oee: "OEE",
  reason: "कारण",
  add_downtime_reason: "डाऊनटाइमचा कारण जोडा",

  default_target: "डिफॉल्ट लक्ष्य",

  enter_in_minutes: "काही मिनिटांत प्रविष्ट करा",
  custome_fields: "सानुकूल फील्ड",
  enter_data_in_minutes: "काही मिनिटांत डेटा प्रविष्ट करा",
  enter_data_in_hours: "तासात डेटा प्रविष्ट करा",
  create_tod_billing_widget: "टॉड बिलिंग विजेट तयार करा",
  edit_tod_billing_widget: "टॉड बिलिंग विजेट संपादित करा",
  choose_from_machine_groups: "मशीन गटांमधून निवडा",
  tod_billing: "टॉड बिलिंग",
  choose: "निवडा",
  create_shift_based_machine_usage_log: "मशीन वापर लॉग तयार करा",
  shift_based_machine_usage_log: "मशीन वापर लॉग",
  rejected: "नाकारले",
  machine_operator: "मशीन ऑपरेटर",
  production_end_timestamp: "उत्पादन समाप्ती वेळ",
  production_start_timestamp: "उत्पादन प्रारंभ वेळ",
  running_time: "चालू वेळ",
  idle_time: "निष्क्रिय वेळ",
  down_time: "खाली वेळ",
  date_row_index: "तारीख पंक्ती",
  date_position: "तारीख स्थिती",
  save: "जतन करा",
  discard: "टाकून द्या",
  adjust: "समायोजित करा",
  safety_incident_sheet: "सुरक्षा घटना पत्रक",
  safety_inspection_sheet: "सुरक्षा तपासणी पत्रक",
  ghg_emission_logs: "जीएचजी उत्सर्जन लॉग",
  add_ghg_emission_data: "जीएचजी उत्सर्जन लॉग जोडा",
  ghg_scope_conversion: "जीएचजी स्कोप रूपांतरण",
  create_ghg_scope_conversion: "जीएचजी स्कोप रूपांतरण तयार करा",
  ghg_data_logs: "जीएचजी उत्सर्जन डेटा लॉग",
  choose_inspection_type_optional: "तपासणी प्रकार निवडा (पर्यायी)",
  supplier_code: "पुरवठादार कोड",
  contact: "संपर्क",
  point_of_contact: "संपर्क बिंदू",
  quantity_supplied: "प्रमाण पुरवठा",
  create_product: "उत्पादन तयार करा",
  products: "उत्पादने",
  data: "डेटा",
  timezone: "टाइमझोन",
  tariffs: "दर",
  days_delta: "दिवस डेल्टा",
  machine_groups: "मशीन गट",
  param_groups: "पार्मेटर गट",
  user_groups: "वापरकर्ता गट",
  topic_prefix: "विषय उपसर्ग",
  message_direction: "संदेश दिशा",
  safety_inspection: "सुरक्षा तपासणी",
  write_inspection_log: "तपासणी लॉग लिहा",
  inspection_id: "तपासणी आयडी",
  inspector_id: "इन्स्पेक्टर आयडी",
  inspection_date: "तपासणी तारीख",
  inspection_type: "तपासणी प्रकार",
  findings: "निष्कर्ष",
  actions_required: "कृती आवश्यक",
  followup_date: "पाठपुरावा तारीख",
  status: "स्थिती",
  safety_incident: "सुरक्षा घटना",
  write_incident: "घटना लिहा",
  incident_description: "घटनेचे वर्णन",
  severity: "तीव्रता",
  accident: "अपघात",
  accident_with_loss: "तोटा सह अपघात",
  closed: "बंद",
  corrective_action: "सुधारात्मक क्रिया",
  employee_id: "कर्मचारी आयडी",
  apply: "अर्ज करा",
  choose_location_optional: "स्थान निवडा (पर्यायी)",
  locations: "स्थाने",
  locations_event_types: "स्थाने इव्हेंट प्रकार",
  create_tariff: "दर तयार करा",
  default_tariff_val: "डीफॉल्ट दर मूल्य",
  add_tariff_data: "दर डेटा जोडा",
  edit_tariff: "दर संपादित करा",
  delete_tariff: "दर हटवा",
  tariff_value: "दर मूल्य",
  machine_status_timeline: "मशीन स्थिती टाइमलाइन",
  total_duration: "एकूण कालावधी",
  generate: "व्युत्पन्न",
  remove_machine: "मशीन काढा",
  edit_machine_status_timeline_widget: "मशीन स्थिती टाइमलाइन विजेट संपादित करा",
  add_machine_Status__timeline_widget: "मशीनची स्थिती टाइमलाइन विजेट जोडा",
  select_timeframe: "टाइमफ्रेम निवडा",
  timeframe_type_shift: "शिफ्ट",
  timeframe_type_custom: "सानुकूल",
  timeframe_type_yesterday: "काल",
  edit_report_details: "अहवाल तपशील संपादित करा",
  download_current_template: "चालू टेम्पलेट डाउनलोड करा",
  date_column_index: "तारीख स्तंभ (अनुक्रमणिका)",
  select_area: "क्षेत्र निवडा",
  select_shift: "शिफ्ट निवडा",
  dashboard_heading: "डॅशबोर्ड",
  production_params: "उत्पादन पॅराम्स",
  no_modules_configured: "कोणतेही मॉड्यूल कॉन्फिगर केलेले नाहीत",
  dashboard_panel: "डॅशबोर्ड पॅनेल",
  choose_type: "प्रकार निवडा",
  name_is_required: "नाव आवश्यक आहे",
  description_is_required: "वर्णन आवश्यक आहे",
  required: "आवश्यक",
  you_have_to_select_something: "आपल्याला काहीतरी निवडावे लागेल",
  name_must_be_less_than_30_characters:
    "नाव 30 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  name_must_be_less_than_25_characters:
    "नाव 25 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  name_must_be_less_than_50_characters:
    "नाव 50 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  description_must_be_less_than_35_characters:
    "वर्णन 35 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  description_must_be_less_than_55_characters:
    "वर्णन 55 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  number_must_be_less_than_6_digits: "संख्या 6 अंकांपेक्षा कमी असणे आवश्यक आहे",
  number_must_be_less_than_8_digits: "संख्या 8 अंकांपेक्षा कमी असणे आवश्यक आहे",
  number_must_be_less_than_7_digits: "संख्या 7 अंकांपेक्षा कमी असणे आवश्यक आहे",
  panel: "पॅनेल",
  gauge: "गेज",
  param: "परम",
  machine: "मशीन",
  add_panel_to_dashboard: "डॅशबोर्डवर पॅनेल जोडा",
  edit_dashboard_panel: "डॅशबोर्ड पॅनेल संपादित करा",
  param_type: "परम प्रकार",
  workspace: "कार्यक्षेत्र",
  device: "डिव्हाइस",
  name: "नाव",
  language: "भाषा",
  time_duration: "वेळ कालावधी",
  submit: "सबमिट करा",
  cancel: "रद्द करा",
  close: "बंद",
  usage: "वापर",
  peak_value: "पीक मूल्य",
  current_data: "वर्तमान डेटा",
  parameter: "पॅरामीटर",
  computed_param: "गणना केलेले परम",
  derived_param: "व्युत्पन्न परम",
  t4_days: "4 दिवस",
  last_7_days: "शेवटचे 7 दिवस",
  last_30_days: "शेवटचे 30 दिवस",
  last_60_days: "शेवटचे 60 दिवस",
  last_90_days: "शेवटचे 90 दिवस",
  last_180_days: "शेवटचे 180 दिवस",
  last_365_days: "लॅट 365 दिवस",
  last_1_hour: "शेवटचा 1 तास",
  last_3_hours: "शेवटचे 3 तास",
  last_6_hours: "शेवटचे 6 तास",
  reorder_views: "पुनर्क्रमित",
  create_view: "तयार करा",
  clear_view_name_filter: "क्लियर फिल्टर",
  view_type: "पहा प्रकार",
  description: "वर्णन",
  table_view: "टेबल दृश्य",
  widget_view: "विजेट दृश्य",
  dynamic_timeframed_widget_view: "डायनॅमिक टाइमफ्रेम्ड विजेट दृश्य",
  diagram_view: "आकृती दृश्य",
  energy_usage_view: "उर्जा वापर दृश्य",
  machine_scroll_view: "मशीन स्क्रोल दृश्य",
  param_scroll_view: "परम स्क्रोल दृश्य",
  consolidated_view: "एकत्रित दृश्य",
  air_quality_view: "हवेची गुणवत्ता दृश्य",
  view_auto_change: "स्वयं बदल पहा",
  no_views_configured: "कोणतीही दृश्ये कॉन्फिगर केलेली नाहीत",
  add_to_dashboard: "डॅशबोर्डमध्ये जोडा",
  edit_view_name: "नाव संपादित करा नाव",
  edit_view: "दृश्य संपादित करा",
  refresh: "रीफ्रेश",
  create_machine: "मशीन तयार करा",
  add_machine: "मशीन जोडा",
  sequence: "अनुक्रम",
  machine_type: "मशीन प्रकार",
  physical_machine: "भौतिक मशीन",
  virtual_machine: "आभासी मशीन",
  positive_workspace: "सकारात्मक कार्यक्षेत्र",
  positive_machine: "सकारात्मक मशीन",
  negative_workspace: "नकारात्मक कार्यक्षेत्र",
  negative_machine: "नकारात्मक मशीन",
  label: "लेबल",
  positive_machine_Ids: "सकारात्मक मशीन आयडी",
  negative_machine_Ids: "नकारात्मक मशीन आयडी",
  trend_analysis_tools: "ट्रेंड विश्लेषण साधने",
  energy_consumption_by_machines: "मशीनद्वारे उर्जा वापर",
  Create_report: "अहवाल तयार करा",
  view_report: "अहवाल पहा",
  tarrif_on: "दर चालू",
  active_energy: "सक्रिय ऊर्जा",
  apparent_energy: "उघड ऊर्जा",
  automate_report: "स्वयंचलित अहवाल",
  add_root_machine: "रूट मशीन जोडा",
  add_common_machine: "सामान्य मशीन जोडा",
  notification_groups: "सूचना गट",
  add_machines_to_list: "सूचीमध्ये मशीन जोडा",
  lists: "याद्या",
  machine_list: "मशीन यादी",
  data_historian: "डेटा इतिहासकार",
  timeframed_trends: "कालखंडातील ट्रेंड",
  dual_axis_trend_comparison: "ड्युअल अक्ष ट्रेंड तुलना",
  granular24_hrs_trend: "ग्रॅन्युलर 24 तास ट्रेंड",
  machinewise_trend: "मशीन निहाय ट्रेंड",
  basic_statistical_trends: "मूलभूत सांख्यिकीय ट्रेंड",
  log_analysis: "लॉग विश्लेषण",
  aqi_comparison_panel: "एक्यूआय तुलना पॅनेल",
  time_of_day_trend: "दिवसाचा ट्रेंड वेळ",
  param_historical_comparisons: "परम ऐतिहासिक तुलना",
  energy_usage_trends: "उर्जा वापराचा ट्रेंड",
  machine_name: "मशीन नाव",
  param_name: "परम नाव",
  trend_view: "ट्रेंड दृश्य",
  raw: "कच्चा",
  trend: "ट्रेंड",
  normalised: "सामान्यीकृत",
  from_date: "तारखेपासून",
  to_date: "आजपर्यंत",
  from: "पासून",
  to: "टू",
  hour: "तास",
  minute: "मिनिट",
  generate_report: "अहवाल व्युत्पन्न करा",
  time_range: "वेळ श्रेणी",
  no_data_available: "कोणताही डेटा उपलब्ध नाही",
  daily: "दररोज",
  monthly: "मासिक",
  weekly: "साप्ताहिक",
  week: "आठवडा",
  yearly: "वार्षिक",
  device_name: "डिव्हाइस नाव",
  machine_wise_comparison: "मशीननुसार तुलना",
  stats_detailed_report: "आकडेवारी तपशीलवार अहवाल",
  log_type: "लॉग प्रकार",
  choose_Log: "लॉग निवडा",
  area: "क्षेत्र",
  aq_device: "एक्यू डिव्हाइस",
  nebeskie_labs_office: "नेबेस्की लॅब ऑफिस",
  date_range: "तारीख_रेंज",
  this_year: "यावर्षी",
  "last 6 months": "शेवटचे 6 महिने",
  "last quater": "शेवटचा तिमाही",
  mtd: "एमटीडी",
  ytd: "वर्ष आजपर्यंत",
  yesterday: "काल",
  custom: "सानुकूल",
  run_report: "चालवा अहवाल",
  create_report: "अहवाल तयार करा",
  usage_report: "वापर अहवाल",
  consolidated_report: "एकत्रित अहवाल",
  dynamic_excel_report: "डायनॅमिक एक्सेल अहवाल",
  energy_usage_report: "उर्जा वापर अहवाल",
  create_action: "क्रिया तयार करा",
  action_type: "कृती प्रकार",
  notification_group: "सूचना गट",
  address: "पत्ता",
  number_of_registers: "नोंदणींची संख्या",
  value: "मूल्य",
  send_notification: "सूचना पाठवा",
  write_parameter: "पॅरामीटर लिहा",
  relays: "रिले",
  relay_1: "रिले 1",
  relay_2: "रिले 2",
  relay_3: "रिले 3",
  relay_4: "रिले 4",
  email_must_be_valid: "ईमेल वैध असणे आवश्यक आहे",
  it_should_be_a_number: "ती एक संख्या असावी",
  it_should_be_a_valid_number: "ही एक वैध संख्या असावी",
  operator: "ऑपरेटर",
  operand: "ऑपरेंड",
  targets: "लक्ष्य",
  target_value: "लक्ष्य मूल्य",
  wait_time_seconds: "प्रतीक्षा वेळ (सेकंद)",
  greater_than: "पेक्षा मोठे",
  modulus_greater_than: "पेक्षा जास्त मॉड्यूलस",
  lesser_than: "पेक्षा कमी",
  modulus_lesser_than: "पेक्षा कमी मॉड्यूलस",
  equal_to: "समान",
  not_equal_to: "समान नाही",
  modulus_equal_to: "समान मॉड्यूलस",
  greater_than_equal_to: "समान पेक्षा मोठे",
  modulus_greater_than_equal_to: "समानपेक्षा जास्त मॉड्यूलस",
  lesser_than_equal_to: "च्या तुलनेत कमी",
  modulus_lesser_than_equal_to: "समानपेक्षा कमी मॉड्यूलस",
  bit_high: "बिट उंच",
  bit_low: "बिट कमी",
  it_should_be_a_positive_whole_number: "ही एक सकारात्मक संपूर्ण संख्या असावी",
  it_should_be_a_positive: "ते एक सकारात्मक असावे",
  create_process: "प्रक्रिया तयार करा",
  edit_process: "प्रक्रिया संपादित करा",
  status_rule: "स्थिती नियम",
  create_process_status_rule: "प्रक्रिया स्थिती नियम तयार करा",
  tag_type: "टॅग प्रकार",
  process_status: "प्रक्रिया स्थिती",
  machines: "मशीन्स",
  running: "धावणे",
  idling: "आळशी",
  offline: "ऑफलाइन",
  no_machines_configured: "कोणतीही मशीन कॉन्फिगर केलेली नाही",
  delete_process: "प्रक्रिया हटवा",
  add_machine_to_process: "प्रक्रियेमध्ये मशीन जोडा",
  delete_premise_area: "प्रीमिस क्षेत्र हटवा",
  add: "जोडा",
  add_to_list: "यादीमध्ये जोडा",
  search: "शोध",
  switch_to: "वर स्विच करा",
  switch_to_base_org: "बेस संस्थेवर स्विच करा",
  switch: "स्विच",
  create_new_organisation: "नवीन संस्था तयार करा",
  organisation_name: "संघटनेचे नाव",
  organisation_type: "संघटना प्रकार",
  country_code: "देश कोड",
  country: "देश",
  state: "राज्य",
  clear: "स्पष्ट",
  change_organisation: "संस्था बदला",
  organisation_id: "संस्था आयडी",
  update_your_oem_logo: "आपला OEM लोगो अद्यतनित करा",
  select_a_logo_to_upload: "अपलोड करण्यासाठी लोगो निवडा",
  submit_logo: "लोगो सबमिट करा",
  create_workspace: "कार्यक्षेत्र तयार करा",
  hub_id: "हब आयडी",
  name_must_be_less_than_45_characters:
    "नाव 45 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  id_is_required: "आयडी आवश्यक आहे",
  no_space_allowed: "जागा परवानगी नाही",
  id_must_be_less_than_6_characters: "आयडी 6 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  edit_workspace: "कार्यक्षेत्र संपादित करा",
  delete_workspace: "कार्यक्षेत्र हटवा",
  add_modbus_device: "मोडबस डिव्हाइस जोडा",
  add_device: "डिव्हाइस जोडा",
  oops: "अरेरे !!",
  ok: "ठीक आहे",
  slave_id: "स्लेव्ह आयडी",
  device_type: "डिव्हाइस प्रकार",
  connection_type: "कनेक्शन प्रकार",
  mGate: "एमगेट",
  make: "बनवा",
  model: "मॉडेल",
  name_must_be_less_than_33_characters:
    "नाव 33 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  name_must_be_less_than_11_characters:
    "नाव 11 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  it_should_be_a_valid_slave_id: "तो एक वैध गुलाम आयडी असावा",
  name_must_be_less_than_4_digits: "नाव 4 अंकांपेक्षा कमी असणे आवश्यक आहे",
  modbus_tcp: "मोडबस TCP",
  modbus_rtu: "मोडबस RTU",
  add_virtual_device: "व्हर्च्युअल डिव्हाइस जोडा",
  add_mqtt_device: "एमक्यूटीटी डिव्हाइस जोडा",
  topic: "विषय",
  identifier: "Identifier",
  identifier_value: "अभिज्ञापक मूल्य",
  name_must_be_less_than_20_characters:
    "नाव 20 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  no_special_characters_or_space_allowed:
    "कोणतेही विशेष वर्ण किंवा स्थान अनुमत नाही",
  must_be_less_than_15_characters: "15 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  create_role: "भूमिका तयार करा",
  role: "भूमिका",
  role_is_required: "भूमिका आवश्यक आहे",
  role_must_be_less_than_40_characters:
    "भूमिका 40 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  role_should_not_contain_white_characters: "भूमिकेमध्ये पांढरे वर्ण नसावेत",
  comparison: "तुलना",
  historical_time_of_day_comparison_parameter:
    "दिवसाची तुलना इतिहास वेळ - पॅरामीटर",
  device_parameter: "डिव्हाइस पॅरामीटर",
  date_1: "तारीख 1",
  date_2: "तारीख 2",
  tod_comparison_report: "टीओडी तुलना अहवाल",
  time_frame: "वेळ फ्रेम",
  download_table: "टेबल डाउनलोड करा",
  table: "टेबल",
  showMinMax_values: "मि आणि कमाल मूल्ये दर्शवा",
  usage_or_average: "वापर किंवा सरासरी",
  min_value: "किमान मूल्य",
  last_12_weeks: "शेवटचे 12 आठवडे",
  last_6_months: "मागील 6 महिने",
  last_12_months: "मागील 12 महिने",
  year_5: "5 वर्षे",
  machinewise_comparison: "मशीननुसार तुलना",
  timeFrame: "टाइमफ्रेम",
  it_should_be_a_date: "ती तारीख असावी",
  time_of_day_parameter: "दिवसाची वेळ- पॅरामीटर",
  tod_report: "TOD - अहवाल",
  in_this_timeframe: "या कालावधीत",
  average: "सरासरी",
  maximum: "जास्तीत जास्त",
  minimum: "किमान",
  energy_usage_trend: "उर्जा वापराचा कल",
  standard_deviation: "Standard Deviation",
  statistical_mode: "सांख्यिकीय मोड",
  production_logs: "उत्पादन नोंदी",
  capacity_logs: "क्षमता लॉग",
  dynamic_logs: "डायनॅमिक लॉग",
  pollutants: "प्रदूषक",
  group_by: "द्वारे गट",
  generate_grouped_trends: "गटबद्ध ट्रेंड व्युत्पन्न करा",
  tCo2: "टीसीओ 2",
  scope_1: "व्याप्ती 1",
  scope_2: "व्याप्ती 2",
  scope_3: "व्याप्ती 3",
  source: "स्त्रोत",
  type: "प्रकार",
  user_access_log: "वापरकर्ता प्रवेश लॉग",
  user_logs: "वापरकर्ता लॉग",
  fetch_logs: "नोंदी आणा",
  update_profile: "प्रोफाइल अद्यतनित करा",
  lang: "लँग",
  theme: "थीम",
  phone: "फोन",
  update: "अद्यतन",
  update_password: "पासवर्ड अपडेट करा",
  change_password: "संकेतशब्द बदला",
  old_password: "जुना संकेतशब्द",
  new_password: "नवीन पासवर्ड",
  confirm_password: "पासवर्डची पुष्टी करा",
  password_must_be_more_than_8_characters:
    "संकेतशब्द 8 पेक्षा जास्त वर्ण असणे आवश्यक आहे",
  passwords_do_not_match: "पासवर्ड जुळत नाहीत",
  add_hub: "हब जोडा",
  id_must_be_less_than_4_characters: "आयडी 4 वर्णांपेक्षा कमी असणे आवश्यक आहे",
  add_maintainer: "देखभालकर्ता जोडा",
  add_parent_org: "पालक संघटना जोडा",
  add_parent_to_organisation: "संस्थेमध्ये पालक जोडा",
  parent_org_id: "पालक संस्था आयडी",
  add_maintainer_to_organization: "संस्थेमध्ये देखभालकर्ता जोडा",
  maintainer_id: "देखभालकर्ता आयडी",
  update_organisation_details: "संस्थेचे तपशील अपडेट करा",
  update_your_organization_details: "आपल्या संस्थेचे तपशील अद्यतनित करा",
  default_email: "डीफॉल्ट ईमेल",
  picker_in_menu: "मेनू मध्ये निवडक",
  param_diagnostics: "परम निदान",
  show_logo: "लोगो दाखवा",
  show_oem_logo: "OEM लोगो दर्शवा",
  organisation_alias: "संस्था उर्फ",
  update_organisation_alias: "संस्था उर्फ ​​अद्यतनित करा",
  update_org_alias: "Org उपनाव अपडेट करा",
  update_your_organization_logo: "आपला संस्था लोगो अद्यतनित करा",
  select_a_logo: "लोगो निवडा",
  update_logo: "लोगो अद्यतनित करा",
  create_uam_user: "यूएएम वापरकर्ता तयार करा",
  overview: "विहंगावलोकन",
  batches: "बॅच",
  track_batch: "ट्रॅक बॅच",
  batch_movement: "बॅच चळवळ",
  production_planner: "उत्पादन नियोजक",
  show_unshipped_only: "केवळ पाठवा दर्शवा",
  view_logs: "लॉग पहा",
  params: "पॅराम्स",
  add_filter: "फिल्टर जोडा",
  filters: "फिल्टर",
  batch_id: "बॅच_आयडी",
  show_details: "तपशील दर्शवा",
  tracing_logs: "ट्रेसिंग लॉग",
  quantity: "प्रमाण",
  location_event_type: "स्थान कार्यक्रम प्रकार",
  fetch_plans: "योजना आणा",
  sku: "एसकेयू",
  skus: "स्टॉक ठेवणे युनिट्स",
  code: "कोड",
  add_production_plans: "उत्पादन योजना जोडा",
  date: "तारीख",
  from_time: "वेळ पासून",
  to_time: "वेळोवेळी",
  activities: "Activities",
  task_name: "कार्य नाव",
  task_details: "कार्य तपशील",
  start_date: "प्रारंभ तारीख",
  end_date: "शेवटची तारीख",
  assignee: "सहाय्यक",
  manager: "व्यवस्थापक",
  activity: "क्रियाकलाप",
  activity_type: "Activity Type",
  escalation_matrix: "एस्केलेशन मॅट्रिक्स",
  maintenance_activity: "देखभाल क्रिया",
  em_level: "ईएम पातळी",
  delay: "विलंब",
  user_group: "वापरकर्ता गट",
  mins_5: "5 मिनिटे",
  mins_10: "10 मिनिटे",
  mins_15: "15 मिनिटे",
  mins_30: "30 मिनिटे",
  hour_1: "1 तास",
  hour_6: "6 तास",
  day_1: "1 दिवस",
  day: "दिवस",
  day_3: "3 दिवस",
  insights: "अंतर्दृष्टी",
  create_emailer_group: "ईमेलर गट तयार करा",
  notification_type: "सूचना प्रकार",
  email: "ईमेल",
  sms: "एसएमएस",
  create_machine_group: "मशीन ग्रुप तयार करा",
  create_param_group: "परम ग्रुप तयार करा",
  create_user_group: "वापरकर्ता गट तयार करा",
  create_module: "मॉड्यूल तयार करा",
  busbar_temp_module: "बसबार टेम्प मॉड्यूल",
  power_quality_module: "उर्जा गुणवत्ता मॉड्यूल",
  esg_module: "ईएसजी मॉड्यूल",
  edge_hub: "एज हब",
  tolerance: "सहिष्णुता",
  ambient_temp_input_type: "सभोवतालचे तापमान इनपुट प्रकार",
  ambient_temp: "वातावरणीय टेम्प",
  workspace_ambient_temp: "वर्कस्पेस वातावरणीय टेम्प",
  device_ambient_temp: "डिव्हाइस वातावरणीय टेम्प",
  r_temperature: "आर तापमान",
  y_temperature: "Y तापमान",
  b_temperature: "B तापमान",
  n_temperature: "एन तापमान",
  rated_current: "रेटेड करंट",
  no_load_current: "लोड चालू नाही",
  r_current: "आर चालू",
  y_current: "Y चालू",
  b_current: "बी चालू",
  n_current: "एन चालू",
  delete_activity: "क्रियाकलाप हटवा",
  substation_voltage_kv: "सबस्टेशन व्होल्टेज (केव्ही)",
  transformer_rating_mva: "ट्रान्सफॉर्मर रेटिंग (एमव्हीए)",
  percentage_impedance_of_the_transformer: "ट्रान्सफॉर्मरची % प्रतिबाधा",
  voltage_rv_y: "व्होल्टेज आरव्ही (वाय)",
  voltage_yb_v: "व्होल्टेज वायबी (व्ही)",
  voltage_br_v: "व्होल्टेज बीआर (व्ही)",
  current_r_a: "वर्तमान आर (अ)",
  current_y_a: "चालू वाय (अ)",
  current_b_a: "वर्तमान बी (अ)",
  thd_i: "थडी i",
  thd_v: "थडी v",
  thd_i1: "थडी i1",
  active_power_kw: "सक्रिय शक्ती (केडब्ल्यू)",
  apparent_power_kva: "स्पष्ट शक्ती (केव्हीए)",
  reactive_power_kvar: "प्रतिक्रियाशील शक्ती (Kvar)",
  active_energy_kwh: "सक्रिय ऊर्जा (केडब्ल्यूएच)",
  apparent_energy_kvah: "उघड ऊर्जा (केव्हीएएच)",
  pf: "पीएफ",
  text: "मजकूर",
  energy_source: "उर्जा स्त्रोत",
  scope: "व्याप्ती",
  system_logs: "सिस्टम लॉग",
  system_logbook: "सिस्टम लॉग बुक",
  esg_data_log: "ईएसजी डेटा लॉग",
  add_esg_data: "ईएसजी डेटा जोडा",
  add_esg_data_log: "ईएसजी डेटा लॉग जोडा",
  source_type: "स्त्रोत प्रकार",
  unit_of_measurement: "मोजमाप युनिट",
  from_date_optional: "तारखेपासून (पर्यायी)",
  emission_factor: "उत्सर्जन घटक (केजीसीओ 2)",
  create_dynamic_logger: "डायनॅमिक लॉगर तयार करा",
  dynamic_log: "डायनॅमिक लॉग",
  primary_field_label: "प्राथमिक फील्ड लेबल",
  primary_field_type: "प्राथमिक फील्ड प्रकार",
  primary: "प्राथमिक",
  additional_fields: "अतिरिक्त फील्ड",
  field_label: "फील्ड लेबल",
  field_type: "फील्ड प्रकार",
  choose_list: "यादी निवडा",
  list: "यादी",
  create_capacity_logger: "क्षमता लॉगर तयार करा",
  capacity_log: "क्षमता लॉग",
  create: "तयार करा",
  create_production_logger: "उत्पादन लॉगर तयार करा",
  production_log: "उत्पादन लॉग",
  production_logger: "उत्पादन लॉगर",
  input_type: "इनपुट प्रकार",
  input: "इनपुट",
  input_name: "इनपुट नाव",
  output_type: "आउटपुट प्रकार",
  output: "आउटपुट",
  output_name: "आउटपुट नाव",
  output_params: "आउटपुट पॅराम्स",
  compute_production: "मोजणी उत्पादन",
  compute_energy: "गणना ऊर्जा",
  energy_param_type: "उर्जा परम प्रकार",
  energy_device: "ऊर्जा डिव्हाइस",
  energy_param: "ऊर्जा परम",
  production_operator: "उत्पादन ऑपरेटर",
  production_correction_factor: "उत्पादन सुधार घटक",
  output_operator: "आउटपुट ऑपरेटर",
  output_correction_factor: "आउटपुट सुधार घटक",
  input_operator: "इनपुट ऑपरेटर",
  input_correction_factor: "आउटपुट सुधार घटक",
  energy_unit: "ऊर्जा एकक",
  production_unit: "उत्पादन एकक",
  production: "उत्पादन",
  create_data_logger: "डेटा लॉगर तयार करा",
  data_log: "डेटा लॉग",
  add_param: "परम जोडा",
  param_id: "परम आयडी",
  create_batch_production_logger: "बॅच उत्पादन लॉगर तयार करा",
  batch_tracing_log: "बॅच ट्रेसिंग लॉग",
  log_name: "लॉग नाव",
  location: "स्थान",
  info: "माहिती",
  remarks: "टीका",
  code_will_be_available_to_log_by_default:
    "कोड डीफॉल्टनुसार लॉग करण्यासाठी उपलब्ध असेल",
  fields: "फील्ड",
  additional_info: "अतिरिक्त माहिती",
  action: "क्रिया",
  report_type: "अहवाल प्रकार",
  create_parameter_report: "पॅरामीटर अहवाल तयार करा",
  data_processing: "डेटा प्रक्रिया",
  addition: "जोड",
  singular: "एकवचनी",
  report: "अहवाल",
  energy_parameters_kwh: "उर्जा मापदंड (केडब्ल्यूएच)",
  apparent_energy_KVAh: "उघड ऊर्जा kvah",
  maximum_demand_MD: "जास्तीत जास्त मागणी (एमडी)",
  excel_frequency: "एक्सेल वारंवारता",
  multiplier: "गुणक",
  single: "एकल",
  incomers: "उत्पन्न करणारे",
  sections: "विभाग",
  energy_distribution_report: "उर्जा वितरण अहवाल",
  root_machine: "रूट मशीन",
  tariff_on: "दर चालू",
  add_root_common_machine: "रूट आणि कॉमन मशीन जोडा",
  common_machine: "सामान्य मशीन",
  energy_bill_distribution_report: "उर्जा विपत्र वितरण अहवाल",
  create_consolidated: "एकत्रित तयार करा",
  choose_report: "अहवाल निवडा",
  id: "आयडी",
  timeframed_sec: "वेळ फ्रेम केलेला से",
  create_dynamic_excel_report: "डायनॅमिक एक्सेल अहवाल तयार करा",
  update_dynamic_excel_report: "डायनॅमिक एक्सेल अहवाल अद्यतनित करा",
  add_sheet: "पत्रक जोडा",
  sheet_name: "पत्रक नाव",
  timestamp: "टाइमस्टॅम्प",
  sheet: "पत्रक",
  sheets: "पत्रके",
  sheet_id: "पत्रक आयडी",
  delete_sheet: "पत्रक हटवा",
  report_name: "अहवाल नाव",
  update_details: "तपशील अद्यतनित करा",
  unit: "युनिट",
  parameters: "मापदंड",
  send_excel_file: "एक्सेल फाइल पाठवा",
  update_excel_template_based_report:
    "एक्सेल टेम्पलेट आधारित अहवाल अद्यतनित करा",
  create_excel_template_based_report: "एक्सेल टेम्पलेट आधारित अहवाल तयार करा",
  upload_excel_template_xlsx: "एक्सेल टेम्पलेट (एक्सएलएसएक्स) अपलोड करा",
  param_equation: "परम समीकरण",
  param_equations: "पॅरामीटर समीकरणे",
  edit_sheets: "पत्रके संपादित करा",
  row_no: "पंक्ती क्र",
  column_no: "स्तंभ क्रमांक",
  row_number: "पंक्ती क्रमांक",
  column_number: "स्तंभ क्रमांक",
  colummn_number: "स्तंभ क्रमांक",
  no_sheets_added: "कोणतीही पत्रके जोडली नाहीत",
  process_based_usage_sheet: "प्रक्रिया आधारित वापर पत्रक",
  process: "प्रक्रिया",
  process_label: "प्रक्रिया लेबल",
  add_tag_types: "टॅग प्रकार जोडा",
  add_process_to_list: "यादीमध्ये प्रक्रिया जोडा",
  machine_tag_based_daily_data_sheet: "मशीन टॅग आधारित दररोज डेटा पत्रक",
  machine_label: "मशीन लेबल",
  add_machine_to_list: "सूचीमध्ये मशीन जोडा",
  add_tag_type_to_list: "सूचीमध्ये टॅग प्रकार जोडा",
  shift_based_usage_sheet: "शिफ्ट आधारित वापर पत्रक",
  status_for_process: "प्रक्रिया",
  status_for_machine: "मशीन",
  esg_summary_item: "सारांश",
  esg_details_item: "तपशील",
  esg_scope1_label: "व्याप्ती 1",
  esg_scope2_label: "व्याप्ती 2",
  esg_scope3_label: "व्याप्ती 3",
  esg_scopes_list_label: "व्याप्ती",
  transpose: "ट्रान्सपोज",
  process_based_timeframed_data_sheet: "प्रक्रिया आधारित टाइमफ्रॅम्ड डेटा शीट",
  add_pocess_to_list: "यादीमध्ये प्रक्रिया जोडा",
  tag_types: "टॅग प्रकार",
  " consolidated_shift_based_usage_data_sheet":
    "एकत्रित शिफ्ट आधारित वापर डेटा पत्रक",
  date_format: "तारीख स्वरूप",
  include_process_total: "प्रक्रिया एकूण समाविष्ट करा",
  machine_group_based_usage_sheet: "मशीन ग्रुप आधारित वापर पत्रक",
  machine_group_label: "मशीन ग्रुप लेबल",
  machine_group: "मशीन ग्रुप",
  add_machine_group_to_list: "सूचीमध्ये मशीन ग्रुप जोडा",
  parameter_based_usage_data_sheet: "पॅरामीटर आधारित वापर डेटा पत्रक",
  "show_month_start&end_data": "महिना प्रारंभ आणि समाप्त डेटा दर्शवा",
  statistics: "आकडेवारी",
  add_param_to_list: "सूचीमध्ये परम जोडा",
  add_timestamp_to_list: "यादीमध्ये टाइमस्टॅम्प जोडा",
  parameter_group_based_timeframed: "पॅरामीटर ग्रुप आधारित टाइमफ्रेम",
  param_group_label: "परम ग्रुप लेबल",
  param_group: "परम ग्रुप",
  add_param_group_to_list: "यादीमध्ये परम ग्रुप जोडा",
  dynamic_logger_based_data_sheet: "डायनॅमिक लॉगर आधारित डेटा शीट",
  dynamic_log_label: "डायनॅमिक लॉग लेबल",
  parameter_equation_based_sheet: "पॅरामीटर समीकरण आधारित पत्रक",
  data_source: "डेटा स्रोत",
  equation_label: "समीकरण लेबल",
  add_equation_to_list: "यादीमध्ये समीकरण जोडा",
  absolute_values: "परिपूर्ण मूल्ये",
  consumption_based_sheet: "उपभोग आधारित पत्रक",
  parameter_name: "पॅरामीटर नाव",
  statistical_analysis_based_sheet: "सांख्यिकीय विश्लेषण आधारित पत्रक",
  parameter_instantaneous_data_sheet: "पॅरामीटर त्वरित डेटा पत्रक",
  create_sheet_column: "पत्रक स्तंभ तयार करा",
  column_label: "स्तंभ लेबल",
  machine_id: "मशीन आयडी",
  create_machine_list: "मशीन यादी तयार करा",
  create_param_list: "पॅरामीटर यादी तयार करा",
  create_sheets: "पत्रके तयार करा",
  create_shift: "शिफ्ट तयार करा",
  description_optional: "वर्णन (पर्यायी)",
  create_sku: "एसकेयू तयार करा",
  create_location: "स्थान तयार करा",
  location_type: "स्थान प्रकार",
  from_location: "स्थान पासून",
  to_location: "स्थान",
  event_type: "कार्यक्रम प्रकार",
  create_location_event_type: "स्थान कार्यक्रम प्रकार तयार करा",
  create_list: "यादी तयार करा",
  create_param_equation: "परम समीकरण तयार करा",
  positive_params: "सकारात्मक पॅराम्स",
  params_to_be_substracted: "पॅराम्स वजा करणे",
  create_combo_param: "कॉम्बो परम तयार करा",
  combo_params: "एकत्रित मापदंड",
  calculated_params: "गणना केलेले पॅरामीटर्स",
  inter_param_operator: "इंटर परम ऑपरेटर",
  correction_operator: "सुधार ऑपरेटर",
  correction_operand: "दुरुस्ती ऑपरेंड",
  create_calculated_param: "गणना केलेली परम तयार करा",
  calculated_param: "गणना परम",
  correction_factor: "दुरुस्ती घटक",
  param1_type: "परम 1 प्रकार",
  param1: "परम 1",
  param1_operator: "परम 1 ऑपरेटर",
  param1_operand: "परम 1 ऑपरेंड",
  param2_type: "परम 2 प्रकार",
  param2: "परम 2",
  param2_operator: "परम 2 ऑपरेटर",
  param2_operand: "परम 2 ऑपरेंड",
  create_esg_scope_conversion: "ईएसजी स्कोप रूपांतरण तयार करा",
  category: "वर्ग",
  esg_scope_logging: "ईएसजी स्कोप लॉगिंग",
  get_from_param: "परमातून मिळवा",
  choose_param: "परम निवडा",
  create_target: "लक्ष्य तयार करा",
  create_word: "शब्द तयार करा",
  words: "शब्द",
  choose_OEM: "OEM निवडा",
  get_the_models: "मॉडेल्स मिळवा",
  get_oem_devices: "OEM डिव्हाइस मिळवा",
  oem_devices: "OEM डिव्हाइस",
  model_number: "मॉडेल क्रमांक",
  register: "नोंदणी करा",
  add_modbus_gateway: "मोडबस गेटवे जोडा",
  " add_modbus_TCP_gateway": "मोडबस टीसीपी गेटवे जोडा",
  IPAddress: "आयपी पत्ता",
  port_number: "बंदर क्रमांक",
  HubID: "हब आयडी",
  baud_rate: "बॉड रेट",
  parity: "समानता",
  stop_bit: "थांबा",
  update_wifi: "वायफाय अद्यतनित करा",
  SSID: "Ssid",
  password: "संकेतशब्द",
  restart_enture: "रीस्टार्ट एन्टेर",
  force_update_configs: "फोर्स अपडेट कॉन्फिगरेशन",
  activity_name: "क्रियाकलाप नाव",
  board: "बोर्ड",
  calender: "कॅलेंडर",
  maintenance: "देखभाल",
  welcome: "आपले स्वागत आहे",
  logout: "लॉगआउट",
  help: "मदत",
  accounts: "खाती",
  organisation: "संस्था",
  pointer_color_optional: "पॉईंटर रंग (पर्यायी)",
  user_id: "वापरकर्ता आयडी",
  confirm_new_password: "नवीन संकेतशब्दाची पुष्टी करा",
  service: "सेवा",
  send_grid: "ग्रिड पाठवा",
  default_enture_view: "डीफॉल्ट एन्टिव्ह व्ह्यू",
  permissions: "परवानग्या",
  areas: "क्षेत्रे",
  remark: "टिप्पणी",
  days: "दिवस",
  yesterdays_aqi: "कालचा AQI",
  aqi: "एक्यूआय",
  choose_air_quality_device: "हवा गुणवत्ता डिव्हाइस निवडा",
  delete_this_device: "हे डिव्हाइस हटवा",
  air_quality_label: "हवा गुणवत्ता लेबल",
  humidity: "आर्द्रता",
  temperature: "तापमान",
  watcher: "निरीक्षक",
  area_reference: "क्षेत्र संदर्भ",
  write_data: "डेटा लिहा",
  day_of_week: "आठवड्याचा दिवस",
  month: "महिना",
  diagnostics_notification_groups: "डायग्नोस्टिक्स सूचना गट",
  hub_name: "हब नाव",
  hub_model: "हब मॉडेल",
  hub_type: "हब प्रकार",
  hub: "हब",
  update_your_MQTT_bridge_config:
    "आपले एमक्यूटीटी ब्रिज कॉन्फिगरेशन अद्यतनित करा",
  MQTT_bridge_mode: "एमक्यूटीटी ब्रिज मोड",
  broker_address: "ब्रोकर पत्ता",
  update_your_OPC_config: "आपले ओपीसी कॉन्फिगरेशन अद्यतनित करा",
  OPC_client_mode: "ओपीसी क्लायंट मोड",
  OPC_server_URL: "ओपीसी सर्व्हर URL",
  user_auth: "वापरकर्ता लेखक",
  mode: "मोड",
  security_policy: "सुरक्षा धोरण",
  Update_your_Edge_Hub_restart_Schedule:
    "आपले एज हब रीस्टार्ट वेळापत्रक अद्यतनित करा",
  auto_restart: "ऑटो रीस्टार्ट",
  time_24Hrs: "वेळ (24 तास)",
  modbus_ping_latency: "मोडबस पिंग लेटेंसी",
  modbus_unhealthy_wait: "मोडबस अस्वास्थ्यकर प्रतीक्षा",
  edit_written_log_book: "लेखी लॉग बुक संपादित करा",
  field_cant_change: "हे फील्ड बदलू शकत नाही",
  re_write: "Re Write",
  write_in_logbook: "लॉगबुकमध्ये लिहा",
  write: "लिहा",
  download_template: "टेम्पलेट डाउनलोड करा",
  write_in_logbook_csv: ".Csv द्वारे लॉग बुकमध्ये लिहा",
  upload_file: "फाइल अपलोड करा",
  batch: "बॅच",
  move_batch: "बॅच हलवा",
  on: "चालू",
  off: "बंद",
  fast_mode: "वेगवान मोड",
  batch_shipping_details: "बॅच शिपिंग तपशील",
  vehicle_no: "वाहन क्र",
  net_weight: "निव्वळ वजन",
  actual_weight: "वास्तविक वजन",
  vendor: "विक्रेता",
  loaded_by: "द्वारे लोड केलेले",
  verified_by: "द्वारे सत्यापित",
  document_no: "दस्तऐवज क्र",
  multiple_machine_param: "एकाधिक मशीन आणि पॅराम्स",
  map_paramid_paramequation_to_row:
    "पंक्तीसाठी नकाशा परम आयडी किंवा परम समीकरण",
  add_current_date_to_sheet: "पत्रकात वर्तमान तारीख जोडा",
  create_parameter_from_template: "टेम्पलेटमधून पॅरामीटर तयार करा",
  selected_parameters_count: "निवडलेले पॅरामीटर्स गणना",
  restricted_user_access: "प्रतिबंधित वापरकर्ता प्रवेश",
  select_all_parameters: "सर्व पॅरामीटर्स निवडा",
  edit_angular_gauge_widget: "अँगुलर गेज विजेट संपादित करा",
  add_angular_gauge_widget: "कोनीय गेज विजेट जोडा",
  widget_label: "विजेट लेबल",
  widget_width: "विजेट रुंदी",
  widget_height: "विजेट उंची",
  param_label: "परम लेबल",
  choose_parameter: "पॅरामीटर निवडा",
  processing: "प्रक्रिया",
  edit_banner_widget: "बॅनर विजेट संपादित करा",
  add_banner_widget: "बॅनर विजेट जोडा",
  edit_bartd_widget: "बार टीडी विजेट संपादित करा",
  add_bar_widget: "बार विजेट जोडा",
  yaxis_label: "Y - Axis Label",
  xaxis_label: "X - अक्ष लेबल",
  y1axis_label: "Y1 - अक्ष लेबल",
  y2axis_label: "वाय 2 - अक्ष लेबल",
  edit_dual_axis_bar_trend_widget:
    "ड्युअल अ‍ॅक्सिस बार ट्रेंड विजेट संपादित करा",
  add_dual_axis_bar_trend_widget: "ड्युअल अ‍ॅक्सिस बार ट्रेंड विजेट जोडा",
  y1_graph_type: "Y1 अक्ष आलेख प्रकार",
  y2_graph_type: "Y2 अक्ष आलेख प्रकार",
  idealValue: "आदर्श मूल्य",
  idealLabel: "आदर्श लेबल",
  add_parameters: "पॅरामीटर्स जोडा",
  add_equation: "समीकरण जोडा",
  add_params: "पॅराम्स जोडा",
  enter_of_machines: "मशीनचा नाही प्रविष्ट करा",
  edit_bar_trend_td_widget: "बार ट्रेंड टीडी विजेट संपादित करा",
  add_bar_trend_td_widget: "बार ट्रेंड टीडी विजेट जोडा",
  graph_type: "आलेख प्रकार",
  aggregation: "एकत्रिकरण",
  default_graph_type: "डीफॉल्ट आलेख प्रकार",
  bar_mode: "बार मोड",
  show_ideal_line: "आदर्श ओळ दर्शवा",
  equations: "समीकरण",
  edit_bitwise_word_widget: "बिटवाईज वर्ड विजेट संपादित करा",
  add_bitwise_word_widget: "बिटवाईज वर्ड विजेट जोडा",
  show_bit_status: "बिट स्थिती दर्शवा",
  edit_bullet_gauge_widget: "बुलेट गेज विजेट संपादित करा",
  add_bullet_gauge_widget: "बुलेट गेज विजेट जोडा",
  reference_value_for: "साठी संदर्भ मूल्य",
  gauge_type: "गेज प्रकार",
  indicator_color: "सूचक रंग",
  busbar_temperature_graph_widget: "बस बार तापमान आलेख विजेट",
  busbar_module: "बस बार मॉड्यूल",
  edit_consumption_gauge_widget: "उपभोग गेज विजेट संपादित करा",
  add_consumption_gauge_widget: "उपभोग गेज विजेट जोडा",
  linearGauge: "रेखीय गेज",
  show_target: "लक्ष्य दर्शवा",
  healthy_on_below_target: "खाली लक्ष्य वर निरोगी",
  edit_consumption_pie_widget: "पाई विजेट संपादित करा",
  add_consumption_pie_widget: "पाई विजेट वापरा",
  add_color_break_point: "कलर ब्रेक पॉईंट जोडा",
  custom_color: "सानुकूल रंग",
  color: "रंग",
  edit_heat_map_widget: "उष्णता नकाशा विजेट संपादित करा",
  add_heat_map__widget: "उष्णता नकाशा विजेट जोडा",
  choose_equation: "समीकरण निवडा",
  label_star: "Label*",
  color_star: "रंग*",
  operator1_star: "ऑपरेटर 1*",
  value1_star: "मूल्य 1*",
  operator2_star: "ऑपरेटर 2*",
  actions: "क्रिया",
  condition: "अटी",
  status_color: "स्थिती रंग",
  text_view: "मजकूर दृश्य",
  edit_process_Status_widget: "प्रक्रिया स्थिती विजेट संपादित करा",
  add_process_Status_widget: "प्रक्रिया स्थिती विजेट जोडा",
  edit_machine_Status_widget: "मशीन स्थिती विजेट संपादित करा",
  add_machine_Status_widget: "मशीन स्थिती विजेट जोडा",
  choose_process: "प्रक्रिया निवडा",
  choose_machine: "मशीन निवडा",
  table_header_label: "टेबल शीर्षलेख लेबल",
  edit_table_TD_widget: "टेबल टीडी विजेट संपादित करा",
  add_table_TD_widget: "टेबल टीडी विजेट जोडा",
  configure_table: "टेबल कॉन्फिगर करा",
  edit_target_based_trend_widget: "लक्ष्य आधारित ट्रेंड विजेट संपादित करा",
  add_target_based_trend_widget: "लक्ष्य आधारित ट्रेंड विजेट जोडा",
  target: "लक्ष्य",
  target_line_label: "लक्ष्य रेखा लेबल",
  edit_usage_score_card: "वापर स्कोअरकार्ड विजेट संपादित करा",
  add_usage_score_card: "वापर स्कोअरकार्ड विजेट जोडा",
  compare_to: "तुलना करा",
  assign_color: "रंग नियुक्त करा",
  enter: "प्रविष्ट करा",
  gauge_to_dashboard: "डॅशबोर्डवर गेज",
  widget_types: "विजेट प्रकार",
  from_value: "मूल्यापासून",
  to_value: "मूल्य",
  machine_status: "मशीन स्थिती",
  deviceA_type: "डिव्हाइस एक प्रकार",
  templates: "टेम्पलेट्स",
  add_triggers_to_parameter: "पॅरामीटरमध्ये ट्रिगर जोडा",
  triggers: "ट्रिगर",
  show_filter: "फिल्टर दर्शवा",
  view_id: "आयडी पहा",
  move_up: "वर जा",
  move_down: "खाली हलवा",
  multiplier_types: "गुणक_प्रकार",
  energy_report: "उर्जा अहवाल",
  add_machine_param: "मशीन परम जोडा",
  aliasing: "अलियासिंग",
  no_zero_read: "शून्य वाचन नाही",
  remove_negative_entropy: "नकारात्मक एन्ट्रोपी काढा",
  calculate_consumption: "वापराची गणना करा",
  disabled: "अक्षम",
  limiter_value: "मर्यादित मूल्य",
  machine_scroll_view_page: "मशीन स्क्रोल पहा पृष्ठ",
  create_mannual_parameter: "मॅन्युअल पॅरामीटर तयार करा",
  restrict_user_access: "वापरकर्ता प्रवेश प्रतिबंधित करा",
  add_device_parameter: "डिव्हाइस पॅरामीटर जोडा",
  entropy: "एन्ट्रोपी",
  idle_latency: "निष्क्रिय विलंब",
  add_OPC_parameter: "ओपीसी पॅरामीटर जोडा",
  node_id: "नोड आयडी",
  byte_format: "बाइट स्वरूप",
  param_scroll_view_page: "परम स्क्रोल पहा पृष्ठ",
  timeframed_sec_report: "वेळ फ्रेम SEC अहवाल",
  prod_unit: "प्रोड युनिट",
  SEC_unit: "सेकंद युनिट",
  energy_parameters: "उर्जा मापदंड",
  prod_param_type: "प्रोड परम प्रकार",
  production_parameters: "उत्पादन मापदंड",
  no_of_intervals: "मध्यांतर नाही",
  granular_data: "ग्रॅन्युलर डेटा",
  csv: "सीएसव्ही",
  delete_rule: "नियम हटवा",
  enable_disable_all_parameters: "सर्व पॅरामीटर्स सक्षम/अक्षम करा",
  disable: "अक्षम करा",
  enable: "सक्षम करा",
  edit_computed_parameter: "संगणकीय पॅरामीटर संपादित करा",
  quantization: "परिमाण",
  command: "आज्ञा",
  register_size: "आकार नोंदवा",
  consumer_ID: "ग्राहक आयडी",
  site_id: "साइट आयडी",
  get_data: "डेटा मिळवा",
  update_param_type: "परम प्रकार अद्यतनित करा",
  write_in_data_logbook: "डेटा लॉगबुकमध्ये लिहा",
  daily_data: "दररोज डेटा",
  log_entry: "लॉग एंट्री",
  enable_Scroll_mode: "स्क्रोल मोड सक्षम करा",
  multiple_areas: "अनेक क्षेत्रे",
  time_of_day: "दिवसाची वेळ",
  capacity_logger: "क्षमता लॉगर",
  provider: "प्रदाता",
  total_sites_received_bills_MTD: "एकूण साइट्सना बिले एमटीडी मिळाली",
  sites_yet_to_receive_bills: "साइट्स अद्याप बिले मिळणार नाहीत",
  total_sites: "एकूण साइट",
  sites_received_bills_MTD: "%साइटला बिले एमटीडी मिळाली",
  total: "एकूण",
  total_bills_received_mtd: "एकूण बिले एमटीडी प्राप्त झाली",
  arrear_1: "थकबाकी 1",
  arrear_2: "थकबाकी 2",
  no_reading: "वाचन नाही",
  bills_received_in_5_days: "मागील 5 दिवसात बिले प्राप्त झाली",
  site_wise_status: "ओके स्थिती व्यतिरिक्त साइट निहाय स्थिती",
  site_wise_final_summary: "साइट निहाय अंतिम सारांश",
  source_link: "स्त्रोत दुवा",
  data_type: "डेटा प्रकार",
  select_an_image_to_upload: "अपलोड करण्यासाठी प्रतिमा निवडा",
  user_group_Id: "वापरकर्ता गट आयडी",
  user_group_name: "वापरकर्ता गट नाव",
  delay_in_mins: "उशीर (मिनिटे)",
  delete_task: "कार्य हटवा",
  watchers: "पहारेकरी",
  comments: "टिप्पण्या",
  asset_under_maintenance: "देखभाल अंतर्गत मालमत्ता",
  start_time: "प्रारंभ वेळ",
  end_time: "शेवटची वेळ",
  download_latest_usage_table: "नवीनतम वापर सारणी डाउनलोड करा",
  tag: "टॅग",
  add_target_data: "लक्ष्य डेटा जोडा",
  edit_target: "लक्ष्य संपादित करा",
  delete_target: "लक्ष्य हटवा",
  prop: "प्रोप",
  excel_template_based_report: "एक्सेल टेम्पलेट आधारित अहवाल",
  configured_hub: "कॉन्फिगर केलेले हब",
  configured_relay: "कॉन्फिगर केलेले रिले",
  desired_state: "इच्छित राज्य",
  configured_notification_group: "कॉन्फिगर केलेले सूचना गट",
  email_ids: "ईमेल आयडी",
  logs: "लॉग",
  unit_id: "युनिट आयडी",
  download_report: "अहवाल डाउनलोड करा",
  frequency: "वारंवारता",
  ip: "आयपी",
  edit_wifi: "वायफाय संपादित करा",
  details: "तपशील",
  connected_to: "जोडलेले",
  software_version: "सॉफ्टवेअर आवृत्ती",
  mac_address: "मॅक पत्ता",
  opc_client_enabled: "ओपीसी क्लायंट सक्षम",
  MQTT_bridge_enabled: "एमक्यूटीटी ब्रिज सक्षम",
  hub_specification: "हब तपशील",
  hub_details: "हब तपशील",
  restart_schedule: "रीस्टार्ट वेळापत्रक",
  restart_device: "डिव्हाइस रीस्टार्ट करा",
  set_up_restart_time: "रीस्टार्ट वेळ सेट अप करा",
  connectivity_logs: "कनेक्टिव्हिटी लॉग",
  internal_cache_size: "अंतर्गत कॅशे आकार",
  default: "डीफॉल्ट",
  configs: "कॉन्फिगरेशन",
  processed: "प्रक्रिया केली",
  space_available: "जागा उपलब्ध",
  kb: "केबी",
  mb: "एमबी",
  last_ping: "शेवटचे पिंग",
  toggle: "टॉगल",
  configured_creds: "कॉन्फिगर केलेले क्रेडिट्स",
  psk: "PSK",
  set_value: "मूल्य सेट करा",
  time_current_time: "वेळ (सध्याचा वेळ उल्लेख नसल्यास घेतला जाईल)",
  Set: "सेट",
  health: "आरोग्य",
  port: "बंदर",
  mqtt_params: "एमक्यूटीटी-पॅराम",
  delete_device: "डिव्हाइस हटवा",
  view_details: "तपशील पहा",
  parameter_id: "पॅरामीटर आयडी",
  email_address: "ईमेल पत्ता",
  attached_triggers: "संलग्न ट्रिगर",
  parameter_details: "पॅरामीटर तपशील",
  enable_write: "लेखन सक्षम करा",
  disable_write: "लेखन अक्षम करा",
  offset: "ऑफसेट",
  no_of_parameters: "पॅरामीटर्स नाही",
  creation_time: "निर्मिती वेळ",
  not_configured: "कॉन्फिगर केलेले नाही",
  add_rule: "नियम जोडा",
  sl_no: "एसएल नाही",
  targeted_by_value: "मूल्यानुसार लक्ष्यित",
  configured_action: "कॉन्फिगर केलेली क्रिया",
  add_action: "कृती जोडा",
  action_id: "कृती आयडी",
  delete_user: "वापरकर्ता हटवा",
  bit: "बिट",
  low: "निम्न",
  high: "उच्च",
  esg_scope_conversion: "ईएसजी स्कोप रूपांतरण",
  batch_production_logs: "बॅच उत्पादन लॉग",
  esg_data_logs: "ईएसजी डेटा लॉग",
  remove_from_dashboard: "डॅशबोर्ड वरून काढा",
  data_logs: "डेटा लॉग",
  select_device: "डिव्हाइस निवडा",
  fetch_connectivity_logs: "कनेक्टिव्हिटी लॉग आणा",
  hub_internet_connectivity_logs: "हब इंटरनेट कनेक्टिव्हिटी लॉग",
  modbus_gateways: "मोडबस गेटवे",
  device_switched_on: "डिव्हाइस चालू आहे",
  device_switched_off: "डिव्हाइस बंद आहे",
  parameter_report: "पॅरामीटर अहवाल",
  timeframed_specific_energy_consumption_report:
    "कालांतराने विशिष्ट उर्जा वापर अहवाल",
  ebill_distribution_report: "ई-बिल वितरण अहवाल",
  root_user_management: "मूळ वापरकर्ता व्यवस्थापन",
  user_acces_management: "वापरकर्ता प्रवेश व्यवस्थापन",
  inference: "अनुमान",
  turn_off: "बंद करा",
  turn_on: "चालू करा",
  diagram_pallette: "आकृती पॅलेट",
  add_component: "घटक जोडा",
  components: "घटक",
  add_text: "मजकूर जोडा",
  add_realtime_data: "रीअलटाइम डेटा जोडा",
  shapes: "आकार",
  lines: "ओळी",
  text_realtime_component: "मजकूर रीअलटाइम घटक",
  shapes_pallette: "आकार पॅलेट",
  lines_pallette: "ओळी पॅलेट",
  click_choose: "एसएलडीमध्ये जोडण्यासाठी आकार क्लिक करा आणि आकार निवडा",
  choose_line: "एसएलडीमध्ये जोडण्यासाठी ओळ निवडा",
  choose_image: "प्रतिमा निवडा",
  upload_image_to_add: "एसएलडीमध्ये जोडण्यासाठी प्रतिमा अपलोड करा",
  add_realtime_textdata: "एसएलडीमध्ये वास्तविक वेळ किंवा मजकूर डेटा जोडा",
  update_user: "वापरकर्ता अद्यतनित करा",
  add_watcher: "निरीक्षक जोडा",
  premise_area: "प्रीमिस क्षेत्र",
  create_computed_param: "संगणकीय परम तयार करा",
  create_derived_param: "व्युत्पन्न परम तयार करा",
  proceed_to_ship: "जहाज वर जा",
  esg_data_collection: "ईएसजी डेटा संग्रह",
  esg_scope_details: "ईएसजी स्कोप तपशील",
  select_custom_date_range: "सानुकूल तारीख श्रेणी निवडा",
  days_365: "365 दिवस",
  days_180: "180 दिवस",
  days_30: "30 दिवस",
  create_user_for_SSO: "एसएसओसाठी वापरकर्ता तयार करा",
  add_widget: "विजेट जोडा",
  add_machine_status_rules: "मशीन स्थिती नियम जोडा",
  add_trigger: "ट्रिगर जोडा",
  add_logged_parameter: "लॉग केलेले पॅरामीटर जोडा",
  edit_logged_parameter: "लॉग केलेले पॅरामीटर संपादित करा",
  create_mannual_param: "मॅन्युअल परम तयार करा",
  add_opc_param: "ओपीसी परम जोडा",
  create_write_block: "लेखन ब्लॉक तयार करा",
  all_parameters: "सर्व पॅरामीटर्स",
  edit_device: "डिव्हाइस संपादित करा",
  edit_modbus_device: "मोडबस डिव्हाइस संपादित करा",
  edit_mqtt_device: "एमक्यूटीटी डिव्हाइस संपादित करा",
  limit_entropy: "एन्ट्रोपी मर्यादित करा",
  emails: "ईमेल",
  power_factor: "पॉवरफॅक्टर (पीएफ)",
  tarrif_rate: "दर दर",
  apparent_power: "उघड शक्ती",
  reactive_power: "प्रतिक्रियाशील शक्ती",
  active_power: "सक्रिय शक्ती",
  energy_usage_view_page: "उर्जा वापर पृष्ठ पहा",
  switch_organisation: "स्विच संस्था",
  wireless_creds: "वायरलेस क्रेडिट्स",
  latest: "नवीनतम",
  value_is: "मूल्य आहे",
  please_take_necessary_action: "कृपया आवश्यक कारवाई करा",
  edit_role: "भूमिका संपादित करा",
  delete_role: "भूमिका हटवा",
  today: "आज",
  days_4: "4 दिवस",
  due: "देय",
  move_to_done: "पूर्ण करा",
  power_system_fault: "पॉवर सिस्टम फॉल्ट गणना (मागील आठवड्यात)",
  alarms: "अलार्म",
  electricity_consumption: "विजेचा वापर (आजपर्यंत महिना)",
  average_demand: "सरासरी मागणी",
  water_use_intensity: "पाण्याचा वापर तीव्रता (डब्ल्यूयूआय)",
  average_water_cost: "सरासरी पाण्याची किंमत (मासिक)",
  water_use_efficiency: "पाण्याचा वापर कार्यक्षमता (डब्ल्यूयूई)",
  batch_logs: "बॅच लॉग",
  edit_log: "लॉग संपादित करा",
  delete_log: "लॉग हटवा",
  dates: "तारखा",
  log_details: "लॉग तपशील",
  view: "दृश्य",
  scope1_emission: "व्याप्ती 1 उत्सर्जन",
  scope2_emission: "स्कोप 2 उत्सर्जन",
  lmtd: "एलएमटीडी",
  company_data: "कंपनी डेटा",
  historian: "इतिहासकार",
  from_optional: "कडून (पर्यायी)",
  to_optional: "ते (पर्यायी)",
  trends: "ट्रेंड",
  generate_trend: "ट्रेंड व्युत्पन्न करा",
  by: "द्वारा",
  check_logs: "लॉग तपासा",
  workspaces: "कार्यक्षेत्र",
  types_of_nodes_connected: "जोडलेले नोड्सचे प्रकार",
  types_of_modbus_device_connected: "मोडबस डिव्हाइसचे प्रकार कनेक्ट केलेले",
  no_of_registers: "नोंदणी नाही",
  write_register: "नोंदणी लिहा",
  setting_view: "सेटिंग दृश्य",
  sign_up: "साइन अप करा",
  reset: "रीसेट",
  mobile_number: "मोबाइल नंबर",
  nebeskie_labs: "नेबेस्की लॅब",
  please_register_and_send_otp:
    "कृपया आपला नोंदणीकृत ईमेल आयडी प्रविष्ट करा आणि ओटीपी पाठवा वर क्लिक करा.",
  reset_password: "संकेतशब्द रीसेट करा",
  back_to_login: "लॉगिन वर परत",
  otp: "ओटीपी",
  send_otp: "ओटीपी पाठवा",
  org_alias: "Org उर्फ",
  please_stand_by: "कृपया उभे रहा",
  total_usage_this_month: "या महिन्यात एकूण वापर",
  total_usage_last_month: "मागील महिन्यात एकूण वापर",
  generate_report_manually: "स्वहस्ते अहवाल तयार करा",
  energy_distribution_detailed_report: "उर्जा वितरण तपशीलवार अहवाल",
  the_report_is_processing: "अहवाल प्रक्रिया करीत आहे",
  enter_active_energy_manually: "सक्रिय उर्जा व्यक्तिचलितपणे प्रविष्ट करा",
  include_others: "इतरांचा समावेश करा",
  bill_amount: "बिल रक्कम",
  yes_delete_it: "होय, ते हटवा",
  create_write_param: "लेखन परम तयार करा",
  report_list: "अहवाल यादी",
  plant_id: "प्लांट आयडी",
  plant_name: "रोपाचे नाव",
  plant_address: "वनस्पती पत्ता",
  add_selected: "निवडलेला जोडा",
  clear_selection: "स्पष्ट निवड",
  real_time_value: "वास्तविक वेळ मूल्य",
  default_status: "डीफॉल्ट स्थिती",
  default_label: "डीफॉल्ट लेबल",
  default_color_optional: "डीफॉल्ट रंग पर्यायी",
  rule_based_status: "नियम आधारित स्थिती",
  show_label_only: "केवळ लेबल दर्शवा",
  show_unit: "युनिट दर्शवा",
  status_rules: "स्थिती नियम",
  configure: "कॉन्फिगरेशन",
  monthly_usage: "मासिक वापर",
  meter_consumption_kwh: "मीटर वापर (केडब्ल्यूएच)",
  total_unit: "एकूण युनिट",
  region: "प्रदेश",
  average_energy_consumed: "सरासरी उर्जा वापरली",
  average_production: "सरासरी उत्पादन",
  average_sec: "सरासरी से",
  total_production: "एकूण उत्पादन",
  total_energy_consumed: "एकूण उर्जा वापरली",
  max_sec: "कमाल से",
  device_1: "डिव्हाइस 1",
  device_parameter_1: "डिव्हाइस पॅरामीटर 1",
  device_2: "डिव्हाइस 2",
  device_parameter_2: "डिव्हाइस पॅरामीटर 2",
  create_param: "परम तयार करा",
  update_tags: "टॅग अद्यतनित करा",
  remove_from_process: "प्रक्रियेमधून काढा",
  machine_parameters: "मशीन पॅरामीटर्स",
  energy: "ऊर्जा",
  power: "शक्ती",
  remove_from_featured: "वैशिष्ट्यीकृत पासून काढा",
  other_parameters: "इतर पॅरामीटर्स",
  add_to_featured: "वैशिष्ट्यीकृत जोडा",
  logged_parameters: "लॉग केलेले पॅरामीटर्स",
  tags: "टॅग्ज",
  machine_status_rules: "मशीन स्थिती नियम",
  connected_communication_devices: "कनेक्ट केलेले संप्रेषण डिव्हाइस",
  mqtt_devices: "एमक्यूटीटी डिव्हाइस",
  modbus_devices: "मोडबस डिव्हाइस",
  total_no_of_params: "पॅराम्सचा एकूण नाही",
  edge_devices: "धार साधने",
  machines_configured: "मशीन्स कॉन्फिगर केली",
  reports_configured: "अहवाल कॉन्फिगर केले",
  views_configured: "दृश्ये कॉन्फिगर केली",
  send_email_notification: "ईमेल सूचना पाठवा",
  enture_send_email_notification:
    "चला निवडलेल्या वापरकर्ता गटाला ईमेल सूचना पाठवूया",
  agree_and_send: "सहमत आणि पाठवा",
  ebill: "Ebil",
  monthly_trend: "मासिक ट्रेंड",
  param_view: "परम व्ह्यू",
  calories: "कॅलरी",
  param_count_view: "परम गणना दृश्य",
  created: "तयार केले",
  api_data_count_view: "एपीआय डेटा गणना दृश्य",
  diesel_emissions: "डिझेल उत्सर्जन",
  eb_emissions: "ईबी उत्सर्जन",
  emissions_kg_co2: "उत्सर्जन केजी-को 2",
  kpis: "केपीआय",
  jan_23: "जाने -23",
  feb_23: "फेब्रुवारी -23",
  mar_23: "मार्च -23",
  apr_23: "एप्रिल -23",
  may_23: "मे -23",
  jun_23: "जून -23",
  lpg_emissions: "एलपीजी उत्सर्जन",
  petrol_emissions: "पेट्रोल उत्सर्जन",
  bullet_gauge: "बुलेट गेज",
  equipment: "उपकरणे",
  enter_no_of_machines: "मशीनचा नाही प्रविष्ट करा",
  shift: "शिफ्ट",
  upload: "अपलोड",
  image_upload: "प्रतिमा अपलोड",
  derived_parameter: "व्युत्पन्न पॅरामीटर",
  value_type: "मूल्य_प्रकार",
  enable_entropy: "एन्ट्रोपी सक्षम करा",
  remove_decimal: "दशांश काढा",
  limiter_entropy: "लिमिटर एन्ट्रोपी",
  show_sku_id: "एसकेयू आयडी दर्शवा",
  production_start_date: "उत्पादन प्रारंभ तारीख (पर्यायी)",
  production_start_time: "उत्पादन प्रारंभ वेळ (पर्यायी)",
  time: "वेळ",
  temperature_c: "तापमान (सी)",
  set_temperature_c: "तापमान सेट करा (सी)",
  no_of_people: "लोक नाही",
  co2_level: "सीओ 2 पातळी",
  aqi_optional: "एक्यूआय पर्यायी",
  delete_sheets: "पत्रके हटवा",
  root_login: "रूट लॉगिन",
  uam_login: "यूएएम लॉगिन",
  login: "लॉगिन",
  add_oem_logo: "OEM लोगो जोडा",
  devices: "उपकरणे",
  derived_data_units: "व्युत्पन्न/डेटा युनिट्स",
  roles: "भूमिका",
  engineering: "अभियांत्रिकी",
  control_panel: "नियंत्रण पॅनेल",
  views: "दृश्ये",
  reports_analysis: "अहवाल आणि विश्लेषण",
  actions_triggers: "क्रिया आणि ट्रिगर",
  modules: "मॉड्यूल",
  processes: "प्रक्रिया",
  air_quality: "हवेची गुणवत्ता",
  manufacturing: "उत्पादन",
  esg: "ईएसजी",
  task_managment: "कार्य व्यवस्थापन",
  sustainability: "टिकाव",
  groups: "गट",
  log_book: "लॉग बुक",
  monitor: "मॉनिटर",
  enture_settings: "सेटिंग्ज",
  configuration: "कॉन्फिगरेशन",
  bar: "बार",
  line: "ओळ",
  s7_device: "एस 7 डिव्हाइस",
  rack: "रॅक",
  slot: "स्लॉट",
  db_number: "डीबी क्रमांक",
  byte_offset: "बाइट ऑफसेट",
  bit_offset: "बिट ऑफसेट",
  data_length: "डेटा लांबी",
  val_type: "मूल्य प्रकार",
  create_s7_device: "एस 7 डिव्हाइस जोडा",
  scope_label: "स्कोप",
  client_safety: "ग्राहकांची सुरक्षा",
  edit: "संपादित करा",
  delete: "हटवा",
  choose_param_to_monitor: "निरीक्षण करण्यासाठी परम निवडा",
  rules: "नियम",
  add_to_row: "पंक्तीमध्ये जोडा",
  add_usage: "वापर जोडा",
  notifications: "सूचना",
  organisation_details: "संघटना तपशील",
  profile: "प्रोफाइल",
  user_access: "वापरकर्ता प्रवेश",
  admin: "प्रशासन",
  shifts: "शिफ्ट",
  add_pie_TD_widget: "पाई टीडी विजेट जोडा",
  edit_pie_TD_widget: "पाई टीडी विजेट संपादित करा",
  legend: "आख्यायिका",
  pie: "पाई",
  edit_bar_trend_widget: "बार ट्रेंड विजेट संपादित करा",
  add_bar_trend_widget: "बार ट्रेंड विजेट जोडा",
  edit_horizantal_bar_trend_widget: "क्षैतिज बार ट्रेंड विजेट संपादित करा",
  add_horizantal_bar_trend_widget: "क्षैतिज बार ट्रेंड विजेट जोडा",
  too_long: "खूप लांब",
  trigger_logs: "ट्रिगर लॉग",
  create_trigger: "ट्रिगर तयार करा",
  right_click_to_configure: "कॉन्फिगर करण्यासाठी राइट क्लिक करा",
  width: "रुंदी",
  height: "उंची",
  hide_value: "मूल्य लपवा",
  edit_machine: "मशीन संपादित करा",
  wait_time: "प्रतीक्षा वेळ",
  production_date: "उत्पादन तारीख",
  connectivity_status: "कनेक्टिव्हिटी स्थिती",
  production_plans: "उत्पादन योजना",
  production_pending: "उत्पादन प्रलंबित",
  pending_shipment: "प्रलंबित शिपमेंट",
  trace_logs: "लॉग ट्रेस करा",
  movement_events: "चळवळीच्या घटना",
  kgco2: "केजीसीओ 2",
  entropy_threshold: "एन्ट्रोपी थ्रेशोल्ड",
  threshold_value: "उंबरठा मूल्य",
  pending_dispatch: "प्रलंबित प्रेषण",
  dispatched: "पाठवले",
  dispatch_details: "पाठवण्याचा तपशील",
  dispatch: "पाठवणे",
  raw_materials: "कच्चा माल",
  pour_qty: "प्रमाण ओतणे",
  summary: "सारांश",
  optional: "पर्यायी",
  param_mqtt_map: "परम एमक्यूटीटी नकाशा",
  machine_status_mqtt_map: "मशीन स्थिती एमक्यूटीटी नकाशा",
  edit_trigger_count_table_widget: "ट्रिगर गणना टेबल विजेट संपादित करा",
  add_trigger_count_table_widget: "ट्रिगर गणना टेबल विजेट जोडा",
  count: "मोजणी",
  real_time_consumption: "वास्तविक वेळ वापर",
  search_view: "शोध दृश्य",
  multiple_entries: "एकाधिक नोंदी",
  combined_parameter: "एकत्रित मापदंड",
  background_color: "पार्श्वभूमी रंग",
  resize: "आकार बदलणे",
  remove: "काढा",
  select_views: "दृश्ये निवडा",
  difference: "फरक",
  sum: "बेरीज",
  set_param_type_for_machine: "मशीनसाठी परम प्रकार सेट करा",
  y1axis: "y1 अक्ष",
  y2axis: "y2 अक्ष",
  temperature_parameters: "तापमान मापदंड",
  area_name: "क्षेत्र नाव",
  maintenance_due_on: "चालू देखभाल",
  days_left: "दिवस बाकी",
  enture_insights: "उद्यम अंतर्दृष्टी",
  reports: "अहवाल",
  till_date_pie_chart: "आजपर्यंत पाई चार्ट",
  till_date_bar_chart: "आजपर्यंत बार चार्ट",
  tod_billing_widget: "टॉड बिलिंग विजेट",
  bar_trend_widget: "बार ट्रेंड विजेट",
  target_based_bar_trend_widget: "लक्ष्य आधारित बार ट्रेंड विजेट",
  gauge_widget: "गेज विजेट",
  consumption_pie_widget: "उपभोग पाई विजेट",
  consumption_gauge_widget: "उपभोग गेज विजेट",
  angular_gauge_widget: "कोनीय गेज विजेट",
  table_widget: "टेबल विजेट",
  banner_widget: "बॅनर विजेट",
  param_equation_banner_widget: "पॅराम इक्वेशन बॅनर विजेट",
  shift_based_usage_table: "शिफ्ट आधारित वापर सारणी",
  bitwise_word_widget: "बिटवाईज वर्ड विजेट",
  dynamic_log_widget: "डायनॅमिक लॉग विजेट",
  machine_status_timeline_widget: "मशीन स्थिती टाइमलाइन विजेट",
  process_or_machine_status_widget: "प्रक्रिया/मशीन स्थिती विजेट",
  parameter_status_based_usage_status: "पॅरामीटर्स्टसबेस्डविजेट",
  parameter_insights_widget: "पॅरामीटर अंतर्दृष्टी विजेट",
  heat_map: "उष्णता नकाशा",
  usage_score_card_widget: "वापर स्कोअर कार्ड विजेट",
  busbar_live_monitoring: "बसबार थेट देखरेख",
  bullet_or_angular_guage_widget: "बुलेट/अँगुलर जीगेज विजेट",
  dual_axis_bar_trend_widget: "ड्युअल अ‍ॅक्सिस बार ट्रेंड विजेट",
  trigger_count_table_widget: "ट्रिगर गणना टेबल विजेट",
  bar_trend_td_widget: "बार ट्रेंड टीडी विजेट",
  horizantal_bar_trend_widget: "क्षैतिज बार ट्रेंड विजेट",
  choose_from_templates: "निवडा_फ्रॅम_टेम्प्लेट्स",
  shift_timeframed_trends: "शिफ्ट आधारित टाइमफ्रेम ट्रेंड",
  candlestick_trends: "CandleStick ट्रेंड",
  free_ram: "Free RAM",
  item: "Item",
  item_code: "Item Code",
  department: "Department",
  departments: "Departments",
  issue_vouchers: "Issue Vouchers",
  issue_voucher: "Issue Voucher",
  to_be_approved: "To Be Approved",
  to_be_issued: "To Be Issued",
  intended: "Intended",
  approved: "Approved",
  received: "Received",
  receiver: "Receiver",
  material: "material",
  material_group: "material group",
  material_desc: "Material Description",
  uom: "Unit Of Measurement",
  create_item_code: "Create Item Code",
  create_department: "Create Department",
  users: "Users",
  items: "Items",
  users_in_department: "Users in Department",
  authorisers_in_department: "Authorisers in Department",
  issuers_in_department: "Issuers in Department",
  item_issuer_list: "Item Issuer List",
  add_item_issuer: "Add Item Issuer",
  store: "Store",
  create_issue_voucher: "Create Issue Voucher",
  voucher_requests: "Voucher Requests",
  intended_qty: "Intended Qty",
  issued_qty: "Issued Qty",
  issue_items: "Issue Items",
  voucher_receipt: "Voucher Receipt",
  add_stock: "Add Stock",
  attached_targets: "Attached Targets",
  attach_target: "Attach Target",
  add_target_to_parameter: "Add Target to Parameter",
  empty: "Empty",
  add_day_wise_target: "Add Day wise Target",
  day_wise_targets: "Day Wise Targets",
  fetch_targets: "Fetch Targets",

  day_wise_machine_status_widget: "दैनिक यंत्र स्थिती विजेट",
  add_day_wise_machine_status_widget: "दैनिक यंत्र स्थिती विजेट जोडा",
  edit_day_wise_machine_status_widget: "दैनिक यंत्र स्थिती विजेट संपादित करा",
  add_machines: "यंत्र जोडा",
  added_machines: "जोडलेली यंत्रे",
  consumption_start_time: "Consumption Start time",
};
