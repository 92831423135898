<template>
  <v-container :dark="$store.getters.getColorPalette().isDark" fluid>
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />
    <!-- <v-row>
            <v-col>
                <span v-if="edit" :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Edit Dynamic Log Widget</span>
                <span v-else :dark="$store.getters.getColorPalette().isDark" :class="'text-subtitle-1'+$store.getters.getColorPalette().accentName+'--text'">Add Dynamic Log Widget</span>
            </v-col>
        </v-row> -->
    <!-- widget form -->
    <v-form
      :dark="$store.getters.getColorPalette().isDark"
      ref="widgetForm"
      v-model="widgetValid"
    >
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border:
            '1px solid ' +
            $store.getters.getColorPalette().panelBorderColorCode,
          'background-color':
            $store.getters.getColorPalette().background2ColorCode,
        }"
        flat
        outlined
        class="px-2 mb-2"
      >
        <v-card-title v-if="edit" class="sub-heading">{{
          $store.getters.getTextMap().edit_dynamic_log_widget
        }}</v-card-title>
        <v-card-title v-else class="sub-heading">{{
          $store.getters.getTextMap().add_dynamic_log_widget
        }}</v-card-title>
        <v-row>
          <!--   Label -->
          <v-col>
            <v-skeleton-loader v-if="loading"></v-skeleton-loader>
            <v-text-field
              dense
              outlined
              v-if="!loading"
              v-model="widgetLabel"
              :counter="30"
              :rules="[rules.required]"
              :label="$store.getters.getTextMap().widget_label"
            >
            </v-text-field>
          </v-col>
          <!-- widget width -->
          <v-col>
            <v-select
              dense
              outlined
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputTextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              v-if="!loading"
              v-model="widgetForm.width"
              :items="widthOptions"
              :label="$store.getters.getTextMap().widget_width"
              item-text="label"
              item-value="value"
              :rules="[rules.required]"
            >
            </v-select>
          </v-col>
          <!-- widget height -->
          <v-col>
            <v-select
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputTextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              v-if="!loading"
              v-model="widgetForm.height"
              :items="heightOptions"
              item-text="label"
              item-value="value"
              :label="$store.getters.getTextMap().widget_height"
              :rules="[rules.required]"
              dense
              outlined
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              v-if="!loading"
              v-model="widgetForm.background_color"
              :items="$store.getters.getBackgroundColorOptions()"
              :label="$store.getters.getTextMap().background_color"
              dense
              outlined
              item-text="label"
              item-value="value"
              clearable
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
            >
              <template v-slot:selection="{ item }">
                <div :style="{ 'background-color': item.value }">
                  {{ item.label }}
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div :style="{ 'background-color': item.value }">
                  {{ item.label }}
                </div>
              </template>
            </v-select>
          </v-col>
          <!-- Time Frame -->
          <v-col v-if="!disableTimeframe">
            <v-select
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette.inputTextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              :items="tdOptions"
              item-text="label"
              item-value="value"
              dense
              outlined
              :label="$store.getters.getTextMap().timeFrame"
              v-model="widgetTimeFrame"
              v-if="!loading"
              :rules="[rules.required]"
            >
            </v-select>
          </v-col>
          <!-- Widget type -->
          <v-col align-self="center">
            <v-select
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              v-if="!loading"
              v-model="graphType"
              :items="graphTypeOptions"
              :label="$store.getters.getTextMap().default_graph_type"
              dense
              outlined
              item-text="label"
              item-value="value"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border:
            '1px solid ' +
            $store.getters.getColorPalette().panelBorderColorCode,
          'background-color':
            $store.getters.getColorPalette().background2ColorCode,
        }"
        flat
        outlined
      >
        <!-- <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background1ColorCode" flat outlined> -->
        <v-card-title>
          {{ $store.getters.getTextMap().choose_fields }}
        </v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark" />
        <v-container fluid>
          <v-row>
            <!-- {{logidOptions}} -->
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-autocomplete
                dense
                outlined
                v-model="logbookID"
                v-if="!loading"
                :counter="15"
                :rules="[rules.required]"
                :items="logidOptions"
                item-text="label"
                item-value="value"
                :label="$store.getters.getTextMap().log_name"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-autocomplete
                dense
                outlined
                multiple
                v-if="!loading"
                :counter="15"
                :rules="[rules.required]"
                :items="paramOptions"
                item-text="label"
                item-value="value"
                :label="$store.getters.getTextMap().fields"
                v-model="widgetParam"
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-checkbox
                :label="$store.getters.getTextMap().primary_field_label"
                v-model="primaryFieldCheck"
              ></v-checkbox>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-checkbox
                v-if="edit"
                :label="$store.getters.getTextMap().order"
                v-model="order"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <!-- </v-card> -->
      </v-card>

      <v-dialog v-model="order" persistent max-width="330">
        <v-card
          :color="$store.getters.getColorPalette().backgroundColorCode"
          :dark="$store.getters.getColorPalette().isDark"
        >
          <h2 class="text-center pt-5">
            {{ $store.getters.getTextMap().order_fields }}
          </h2>

          <v-list :color="$store.getters.getColorPalette().backgroundColorCode"
          :dark="$store.getters.getColorPalette().isDark">
            <v-list-item
              v-for="(item, index) in paramOptions"
              :key="item.label"
            >
              <span>{{ item.label }}</span>
              <v-spacer></v-spacer>

              <v-btn
                fab
                elevation="2"
                small
                class="mx-2"
                @click="increaseIndex(index)"
                :color="$store.getters.getColorPalette().backgroundColorCode"
      :dark="$store.getters.getColorPalette().isDark"
              >
                <v-icon
                  :isDark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().accentCode"
                  size="24"
                  >mdi-arrow-up-bold-circle-outline</v-icon
                >
              </v-btn>
              <v-btn fab elevation="2" small @click="decreaseIndex(index)"  :color="$store.getters.getColorPalette().backgroundColorCode"
              :dark="$store.getters.getColorPalette().isDark">
                <v-icon
                  :isDark="$store.getters.getColorPalette().isDark"
                  :color="$store.getters.getColorPalette().accentCode"
                  size="24"
                  >mdi-arrow-down-bold-circle-outline</v-icon
                >
              </v-btn>
            </v-list-item>
          </v-list>

          <div class="d-flex justify-center py-5">
            <v-btn
              @click="order = false"
              class="mr-4"
              :color="$store.getters.getColorPalette().submitbtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              >{{ $store.getters.getTextMap().finish }}</v-btn
            >

            <v-btn
              @click="order = false"
              :color="$store.getters.getColorPalette().deletebtnColor"
              :dark="$store.getters.getColorPalette().isDark"
              >{{ $store.getters.getTextMap().close }}</v-btn
            >
          </div>
        </v-card>
      </v-dialog>

      <v-row no-gutters>
        <v-spacer />
        <v-col cols="auto" class="d-flex mt-2" align="right">
          <v-btn
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().submitbtnColor"
            small
            filled
            @click="onSubmit"
            >{{ $store.getters.getTextMap().submit }}</v-btn
          >
          <v-btn
            class="ml-2"
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().cancelbtnColor"
            small
            @click="onClose"
            >{{ $store.getters.getTextMap().cancel }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import InfoAlert from "@/components/InfoAlert";
import FormRules from "@/utillities/FormRules";
import axios from "axios";
export default {
  name: "AddDynamicLogWidget",
  props: ["widget", "seq", "disableTimeframe"],
  components: {
    InfoAlert,
  },
  data() {
    return {
      edit: false,
      widgetValid: false,
      api: "createWidgetForView",
      showDismissibleAlert: false,
      info: "",
      loading: false,
      order: false,
      rules: FormRules.rules,
      widthOptions: [
        { label: "Full screen", value: 12 },
        { label: "Half screen", value: 6 },
        { label: "1/3 screen", value: 4 },
        { label: "Quarter screen", value: 3 },
        { label: "3/4 screen", value: 9 },
        { label: "Auto", value: null },
      ],
      heightOptions: [
        { label: "Fixed", value: "fixed" },
        { label: "Flex", value: "flex" },
      ],
      tdOptions: [
        { label: "last 30 days", value: "30" },
        { label: "last 60 days", value: "60" },
        { label: "last 90 days", value: "90" },
        { label: "last 180 days", value: "180" },
        { label: "last 365 days", value: "365" },
      ],
      graphTypeOptions: [
        { label: "Bar", value: 2 },
        { label: "Line", value: 1 },
      ],
      graphType: null,
      widgetLabel: null,
      widgetParam: [],
      widgetTimeFrame: null,
      logidOptions: [],
      meta: {},
      logbookID: null,
      param: null,
      primaryFieldCheck: false,
      widgetForm: {
        meta: {},
        log_id: null,
        parameter: null,
      },
      dynamicLogData: null,
      tableData: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    paramOptions() {
      let op = [];
      if (this.logbookID && this.logbookID.meta) {
        for (let i of Object.keys(this.logbookID.meta)) {
          op.push({ label: i, value: i });
        }
      }
      return op;
    },
  },
  methods: {
    init() {
      if (this.widget && this.widget.widget_id && this.widget.view_id) {
        this.edit = true;
        this.widgetForm = Object.assign({}, this.widget);
        this.api = "updateWidget";
        this.widgetLabel = this.widgetForm.label;
        if (!this.widgetForm.meta) {
          this.widgetForm.meta = { data: {} };
        } else {
          this.widgetParam = this.widgetForm.meta.data;
          this.logbookID = this.widgetForm.meta.log_details;
          this.graphType = this.widgetForm.meta.graphType;
          this.widgetTimeFrame = this.widgetForm.meta.time_frame;
          this.graphType = this.widgetForm.meta.graph_type;
          if (
            this.widgetForm.meta.primary_field &&
            this.widgetForm.meta.primary_field_label
          ) {
            this.primaryFieldCheck = true;
          }
        }
      } else {
        if (this.widget.view_id) {
          this.api = "createWidgetForView";
          this.widgetForm = Object.assign({}, this.widget);
          this.widgetForm.meta = { data: [] };
          this.widgetForm.seq = this.seq ? this.seq : 0;
        }
      }
      this.getDynamicLogData();
    },
    getDynamicLogData() {
      axios
        .get(this.$store.state.api + "/dynamicLogs", {
          headers: { Authorization: "Bearer " + this.$store.state.jwt },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.logidOptions = [];
            this.dynamicLogData = response.data.logs;
            response.data.logs.forEach((item) => {
              this.logidOptions.push({
                label: item.name,
                value: item,
              });
            });
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.info = err;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs.widgetForm.validate();
      if (this.widgetValid) {
        this.meta["log_details"] = this.logbookID;
        if (this.primaryFieldCheck) {
          this.meta["primary_field_label"] = this.logbookID.primary_field_label;
          this.meta["primary_field"] = this.logbookID.primary_field;
          this.meta["primary_field_type"] = this.logbookID.primary_field_type;
        }
        this.meta["time_frame"] = this.widgetTimeFrame;
        this.meta["data"] = this.widgetParam;
        this.meta["graph_type"] = this.graphType;
        this.widgetForm.label = this.widgetLabel;
        this.widgetForm.view_id = this.widget.view_id;
        this.widgetForm.meta = this.meta;
        this.widgetForm.widget_type = this.widget.widget_type;
        this.widgetForm.seq = this.widget.seq;
        axios
          .post(this.$store.state.api + this.api, this.widgetForm, {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
            if(this.edit){
                this.info = "Widget Updated";
            }else{
                this.info = "Widget Created"
            }
              this.loading = false;
              this.showDismissibleAlert = true;
              this.$refs.widgetForm.reset();
              this.$emit("success");
              this.$emit("close");
            }
          });
      }
    },
    onClose() {
      this.$refs.widgetForm.reset();
    },
    updateOrder() {
      this.paramOptions.map((item, index) => {
        item["index"] = index;
      });
    },
    increaseIndex(index) {
      if (index > 0) {
        let temp = this.paramOptions[index];
        this.paramOptions[index] = this.paramOptions[index - 1];
        this.paramOptions[index - 1] = temp;
      }
      this.syncWidgetParam();
    },
    decreaseIndex(index) {
      if (index < this.paramOptions.length - 1) {
        let temp = this.paramOptions[index];
        this.paramOptions[index] = this.paramOptions[index + 1];
        this.paramOptions[index + 1] = temp;
      }
      this.syncWidgetParam();
    },
    syncWidgetParam() {
      this.widgetParam = this.paramOptions.map((item) => item.value);
    },
  },
  watch: {
    widget() {
      this.init();
    },
    order() {
      this.updateOrder();
    },
  },
};
</script>

<style scoped>
.editing {
  border: 1px solid #ccc;
  padding: 2px;
}
</style>